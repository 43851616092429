export default {
    Europe: {
        primaryRace: 'W',
        items: [
            'Western',
            'Denmark',
            'France',
            'Germany',
            'Netherlands',
            'Norway',
            'Poland',
            'Russia',
            'Sweden',
            'Ukraine',
            'UK',
            'Italy',
            'Spain',
            'Portugal',
            'Albania',
            'Andorra',
            'Armenia',
            'Austria',
            'Belarus',
            'Belgium',
            'Bosnia and Herzegovina',
            'Bulgaria',
            'Croatia',
            'Cyprus',
            'Czech Republic',
            'Estonia',
            'Finland',
            'Georgia',
            'Greenland',
            'Greece',
            'Hungary',
            'Iceland',
            'Ireland',
            'Kosovo',
            'Latvia',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macedonia',
            'Malta',
            'Moldova',
            'Monaco',
            'Montenegro',
            'Romania',
            'San Marino',
            'Serbia',
            'Slovakia',
            'Slovenia',
            'Switzerland'
        ]
    },
    AmericaN: {
        primaryRace: 'W',
        items: ['USA', 'Canada', 'Australia', 'New Zealand']
    },
    Latin: {
        primaryRace: 'L',
        namesKey: 'Spain',
        items: [
            'Argentina',
            'Bolivia',
            'Brazil',
            'Chile',
            'Colombia',
            'Ecuador',
            'Guyana',
            'Paraguay',
            'Peru',
            'Suriname',
            'Uruguay',
            'Venezuela',
            'Cuba',
            'El Salvador',
            'Guatemala',
            'Nicaragua',
            'Panama',
            'Honduras',
            'Dominican Republic',
            'Costa Rica'
        ] // do not use the following countries: 'Dominica', 'Haiti', 'Trinidad and Tobago', 'Jamaica', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Belize', 'Barbados', 'The Bahamas', 'Antigua and Barbuda', 'Grenada'],
    },
    Arab: {
        primaryRace: 'A',
        namesKey: 'Arab',
        items: [
            'Afghanistan',
            'Algeria',
            'Azerbaijan',
            'Bahrain',
            'Egypt',
            'Iran',
            'Iraq',
            'Israel',
            'Jordan',
            'Kuwait',
            'Lebanon',
            'Libya',
            'Morocco',
            'Oman',
            'Pakistan',
            'Qatar',
            'Saudi Arabia',
            'Somalia',
            'Syria',
            'Tunisia',
            'Turkey',
            'United Arab Emirates',
            'Yemen',
            'Turkmenistan',
            'Tajikistan',
            'Uzbekistan'
        ]
    },
    Africa: {
        primaryRace: 'R',
        namesKey: 'Africa',
        items: [
            'African',
            'Angola',
            'Benin',
            'Botswana',
            'Burkina Faso',
            'Burundi',
            'Cameroon',
            'Cape Verde',
            'Central African Republic',
            'Chad',
            'Comoros',
            'Republic of the Congo',
            'Democratic Republic of the Congo',
            "Cote d'Ivoire",
            'Djibouti',
            'Equatorial Guinea',
            'Eritrea',
            'Ethiopia',
            'Gabon',
            'The Gambia',
            'Ghana',
            'Guinea',
            'Guinea-Bissau',
            'Kenya',
            'Lesotho',
            'Liberia',
            'Madagascar',
            'Malawi',
            'Mali',
            'Mauritania',
            'Mauritius',
            'Mozambique',
            'Namibia',
            'Niger',
            'Nigeria',
            'Rwanda',
            'Sao Tome and Principe',
            'Senegal',
            'Seychelles',
            'Sierra Leone',
            'South Africa',
            'South Sudan',
            'Sudan',
            'Swaziland',
            'Tanzania',
            'Togo',
            'Uganda',
            'Zambia',
            'Zimbabwe'
        ]
    },
    India: {
        primaryRace: 'I',
        namesKey: 'India',
        items: ['India', 'Bangladesh', 'Bhutan', 'Myanmar', 'Sri Lanka', 'Nepal', 'Maldives']
    },
    AsiaSE: {
        primaryRace: 'S',
        items: [
            'Brunei',
            'Cambodia',
            'Indonesia',
            'Laos',
            'Malaysia',
            'Vietnam',
            'Philippines',
            'Thailand',
            'East Timor',
            'Fiji',
            'Kiribati',
            'Marshall Islands',
            'Federated States of Micronesia',
            'Nauru',
            'Palau',
            'Papua New Guinea',
            'Samoa',
            'Solomon Islands',
            'Tonga',
            'Tuvalu',
            'Vanuatu'
        ]
    },
    China: {
        primaryRace: 'C',
        items: [
            'Asian',
            'China',
            'Japan',
            'North Korea',
            'South Korea',
            'Singapore',
            'Mongolia',
            'Taiwan',
            'Kazakhstan',
            'Kyrgyzstan'
        ]
    }
};
