<template>
  <div class="ProfessionTest">
    <MultipleChoiceInputStandard
      ref="input"
      :items="data.options"
      :selections="selectedFragmentIndices"
      @item-click="handleItemClick" />
  </div>
</template>

<script>
import MultipleChoiceInputStandard from '@/modules/games-shared/components/MultipleChoiceInputStandard';

export default {
    components: { MultipleChoiceInputStandard },
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    inject: ['transition'],
    data() {
        return {
            selectedFragmentIndices: []
        };
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    this.$refs.input.playFeedbackCorrect().then(() => {
                        this.$emit('feedback-completed');
                    });
                } else {
                    this.$refs.input.playFeedbackWrong().then(() => {
                        this.$emit('feedback-completed');
                    });
                }
            });
        }
    },
    methods: {
        handleItemClick(optionIndex) {
            this.selectedFragmentIndices.push(optionIndex);
            this.transition('SUBMIT_ANSWER', { value: this.data.options[optionIndex] });
        },
        cheat() {
            this.selectedFragmentIndices = [1];
            this.transition('SUBMIT_ANSWER', { value: this.data.fasit });
        }
    }
};
</script>

<style scoped lang="scss">
.ProfessionTest {
}
</style>
