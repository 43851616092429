import axios from 'axios';
import settings from '@/settings';

const faceMap = {};
const raceMap = {};

// Explain A: Arab, L: Latin, C: China, I:India, W: Western, R: Africa, S: Southest Asia

function getFace({ faceId, gender, race, age }, availableMap) {
    let face;
    if (faceId) {
        face = faceMap[faceId];
    } else {
        const available = availableMap[race];
        let index = -1;
        if (gender) {
            index = available.findIndex(id => id && faceMap[id].gender === gender);
        } else {
            index = available.findIndex(id => id);
        }
        face = faceMap[available[index]];
        available[index] = null;
    }

    return {
        image: face.image,
        faceId: face.id,
        gender: face.gender,
        race,
        age
    };
}

let loadPromise = null;
function load() {
    if (!loadPromise) {
        loadPromise = new Promise(resolve => {
            const url = `${settings.endpoints.memomapsData}/faces.json?t=${Date.now()}`;
            axios
                .get(url)
                .then(response => {
                    const faces = response.data;

                    faces.forEach(face => {
                        faceMap[face.id] = face;
                        if (raceMap[face.race]) {
                            raceMap[face.race].push(face);
                        } else {
                            raceMap[face.race] = [face];
                        }
                    });
                    resolve();
                })
                .catch(error => {
                    console.log(error); // eslint-disable-line no-console
                    resolve();
                });
        });
    }
    return loadPromise;
}

export default {
    faceMap,
    raceMap,
    getFace,
    load
};
