import shuffle from 'lodash-es/shuffle';
import { splitString, getRandomInt } from '@/modules/memogenius-game/utils';

import firstnameF from './data/firstnameF';
import firstnameM from './data/firstnameM';
import surnames from './data/surnames';
import regions from './data/regions';

const races = {};
const countryToRegion = {};

Object.entries(regions).forEach(([c, region]) => {
    region.items.forEach(item => {
        races[item] = region.primaryRace;
        countryToRegion[item] = region;
    });
});

// firstnameF['Canada'] = firstnameF['USA']
// firstnameM['Canada'] = firstnameM['USA']
// surnames['Canada'] = surnames['USA']

function randomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

function getNameList(country, gender) {
    const map = gender === 'M' ? firstnameM : firstnameF;
    let namesKey = country;
    if (!map[namesKey]) {
        if (countryToRegion[namesKey]) {
            namesKey = countryToRegion[country].namesKey;
        }
    }
    if (!namesKey) {
        namesKey = 'USA';
    }
    return map[namesKey];
}

function getSurnameList(country, gender) {
    let list = surnames[country + gender] || surnames[country];
    if (!list) {
        country = (countryToRegion[country] && countryToRegion[country].namesKey) || 'USA';
    }
    list = surnames[country + gender] || surnames[country];
    return list;
}

function getFragmentsFromList(list, numberOfFragments, include) {
    const fragments = [];
    const seen = {};

    // NOTE: fragments from 'include' does not have to be unique. Example: 'finfin' (no), where we need both parts to form the answer
    include.forEach(word => {
        word = word.toLowerCase();
        const [part1, part2] = splitString(word);
        fragments.push(part1);
        seen[part1] = true;
        fragments.push(part2);
        seen[part2] = true;
    });

    while (fragments.length < numberOfFragments) {
        let word = list[getRandomInt(0, list.length)];
        if (word.indexOf('/') !== -1) {
            word = randomItem(word.split('/'));
        }
        word = word.toLowerCase();
        const [part1, part2] = splitString(word);
        if (!seen[part1]) {
            fragments.push(part1);
            seen[part1] = true;
        }
        if (fragments.length === numberOfFragments) {
            break;
        }
        if (!seen[part2]) {
            fragments.push(part2);
            seen[part2] = true;
        }
    }

    return shuffle(fragments);
}

export default {
    firstnameF,
    firstnameM,
    surnames,

    getNameList,

    getNameFragments(numberOfFragments, include, country, gender) {
        const list = getNameList(country, gender);
        return getFragmentsFromList(list, numberOfFragments, include);
    },

    getSurnameFragments(numberOfFragments, include, country, gender) {
        const list = getSurnameList(country, gender);
        return getFragmentsFromList(list, numberOfFragments, include);
    },

    getName({ name, gender, country = 'USA' }) {
        if (typeof name === 'string') {
        } else {
            let list = getNameList(country, gender);
            name = randomItem(randomItem(list).split('/'));
        }
        return {
            name,
            gender,
            country
        };
    },
    getSurname({ surname, gender, country = 'USA' }) {
        let list = getSurnameList(country, gender);
        surname = randomItem(randomItem(list).split('/'));
        return {
            surname,
            gender,
            country
        };
    },

    getRace(country) {
        return races[country];
    },

    generateRacesForCountry(country, total) {
        const arr = [];
        for (let i = 0; i < total; i++) {
            let race;
            let percent = (i + 1) / total;
            if (!country || country === 'USA') {
                if (percent < 0.7) {
                    race = 'W';
                } else if (percent < 0.85) {
                    race = 'L';
                } else if (percent < 0.95) {
                    race = 'A';
                } else if (percent < 0.975) {
                    race = 'C';
                } else {
                    race = 'I';
                }
            } else {
                if (percent < 0.9) {
                    race = races[country];
                } else {
                    race = randomItem(['W', 'L', 'A', 'C', 'R', 'I', 'S']);
                }
            }
            arr.push(race || 'W');
        }
        return arr;
    }
};
