export default {
    USA: [
        'Carson/Carter',
        'Noah',
        'Mason',
        'Cameron',
        'Christopher',
        'Christian',
        'Santiago',
        'Charles',
        'Aiden/Ayden',
        'Tyler',
        'Adam',
        'Logan',
        'Juan',
        'Cooper',
        'Andrew',
        'Colton',
        'Hunter',
        'Aaron',
        'Oliver',
        'Owen',
        'Gabriel',
        'Michael',
        'Jordan',
        'Brayden',
        'Camden',
        'Easton',
        'Henry',
        'Dylan',
        'Richard',
        'Brandon',
        'Wyatt',
        'David',
        'Damian',
        'Benjamin',
        'Dominic',
        'Matthew/Matias/Mateo',
        'Diego',
        'Grayson',
        'Samuel',
        'Chase',
        'Ethan',
        'Isaac/Isaiah',
        'Daniel',
        'Caleb',
        'Jacob',
        'Levi',
        'Justin',
        'Thomas',
        'Lucas/Luke',
        'Bentley',
        'Adrian',
        'Ryan',
        'James',
        'Evan',
        'Parker',
        'Robert',
        'Hudson',
        'Nicholas',
        'Jackson',
        'Blake',
        'Austin',
        'Liam',
        'William',
        'Lincoln',
        'John/Johnathan',
        'Jack',
        'Gavin',
        'Alexander',
        'Angel',
        'Brody',
        'Connor',
        'Eli/Elijah',
        'Ian',
        'Jase/Jason/Jaxon/Jaxson',
        'Jayden',
        'Jeremiah',
        'Jose',
        'Joseph',
        'Joshua',
        'Josiah',
        'Julian',
        'Kayden',
        'Kevin',
        'Landon',
        'Luis',
        'Moshe',
        'Nathan/Nathaniel',
        'Nolan',
        'Sebastian',
        'Tristan',
        'Xavier',
        'Zachary'
    ],

    Spain: [
        'Adrián',
        'Agustín',
        'Aitor',
        'Albert',
        'Alberto',
        'Alejandro',
        'Alex',
        'Alexander',
        'Alfonso',
        'Alvaro',
        'Amin',
        'Ander',
        'Andrés',
        'Angel',
        'Antonio',
        'Arnau',
        'Asier',
        'Beñat',
        'Benjamín',
        'Borja',
        'Bruno',
        'Carlos',
        'Cristian',
        'Cristóbal',
        'Dani',
        'Daniel',
        'Darío',
        'David',
        'Diego',
        'Domingo',
        'Emiliano',
        'Enrique',
        'Erik',
        'Felipe',
        'Fernando',
        'Fran',
        'Francisco',
        'Gabriel',
        'Gonzalo',
        'Gorka',
        'Guille',
        'Guillermo',
        'Hugo',
        'Igor',
        'Iker',
        'Iñaki',
        'Ismael',
        'Ivan',
        'Jaime',
        'Javi',
        'Javier',
        'Jesus',
        'Jesús',
        'Joan',
        'Joaquín',
        'Jon',
        'Jonan',
        'Jonathan',
        'Jorge',
        'Joris',
        'José',
        'Jose Antonio',
        'Jose Luis',
        'Jose Manuel',
        'Jose Miguel',
        'Josep',
        'Juan',
        'Juan Carlos',
        'Lautaro',
        'Leonardo',
        'Lucas',
        'Luis',
        'Manolo',
        'Manu',
        'Manuel',
        'Marc',
        'Marcelo',
        'Marco',
        'Marcos',
        'Mario',
        'Martín',
        'Mateo',
        'Matías',
        'Maximiliano',
        'Miguel',
        'Miguel Angel',
        'Mohamed',
        'Nacho',
        'Nevil',
        'Nicolas',
        'Nicolás',
        'Oscar',
        'Pablo',
        'Paco',
        'Paul',
        'Pedro',
        'Pepe',
        'Pol',
        'Rafa',
        'Rafael',
        'Ramón',
        'Raul',
        'Roberto',
        'Rodrigo',
        'Rubén',
        'Samuel',
        'Santi',
        'Santiago',
        'Santino',
        'Saul',
        'Sebastián',
        'Sergio',
        'Tomás',
        'Vicente',
        'Víctor'
    ],

    Portugal: [
        'Adriano',
        'Afonso',
        'Alef',
        'Alex',
        'Alexandre',
        'Alexsandro',
        'Aline',
        'Anderson',
        'Andre',
        'Arthur',
        'Arthur',
        'Artur',
        'Augusto',
        'Bernardo',
        'Brendon',
        'Breno',
        'Bruno',
        'Caio',
        'Carlos',
        'Claudio',
        'Daniel',
        'Danilo',
        'Davi',
        'Davi',
        'Dhiogo',
        'Diego',
        'Diogo',
        'Douglas',
        'Eduardo',
        'Elias',
        'Erick',
        'Fábio',
        'Fabrício',
        'Felipe',
        'Fernando',
        'Flavio',
        'Francisco',
        'Francisco',
        'Gabriel',
        'Geovanne',
        'Giliard',
        'Gonçalo',
        'Guilherme',
        'Guilherme',
        'Gustavo',
        'Henrique',
        'Hugo',
        'Igor',
        'Italo',
        'Jean',
        'Jefferson',
        'João',
        'João',
        'João Eduardo',
        'João Pedro',
        'João Vitor',
        'Jonathan',
        'Jorge',
        'José',
        'Jose Matheus',
        'Josue',
        'Julio',
        'Junior',
        'Kaue',
        'Leandro',
        'Leonardo',
        'Leticia',
        'Luan',
        'Lucas',
        'Luciano',
        'Luis',
        'Luis Felipe',
        'Luiz',
        'Luiz Henrique',
        'Maicon',
        'Marcelo',
        'Marcos',
        'Martim',
        'Matheus',
        'Mauricio',
        'Miguel',
        'Murilo',
        'Nathan',
        'Nícolas',
        'Pablo',
        'Patrick',
        'Paulo',
        'Pedro',
        'Pedro',
        'Rafael',
        'Rafael',
        'Ramon',
        'Raphael',
        'Raul',
        'Renan',
        'Ricardo',
        'Robert',
        'Roberto',
        'Robson',
        'Rodrigo',
        'Samuel',
        'Santiago',
        'Sidney',
        'Thiago',
        'Tomás',
        'Vagner',
        'Victor',
        'Vinicius',
        'Vitor',
        'Wesley',
        'Willian'
    ],

    Germany: [
        'Aaron',
        'Adrian',
        'Alexander',
        'Anton',
        'Artur',
        'Bastian',
        'Ben',
        'Benedikt',
        'Benjamin',
        'Bennet',
        'Collin',
        'Damian',
        'Daniel',
        'David',
        'Dominik',
        'Elias',
        'Emil',
        'Erik',
        'Fabian',
        'Felix',
        'Finn',
        'Florian',
        'Fynn',
        'Gabriel',
        'Hannes',
        'Henri',
        'Jakob',
        'Jamie',
        'Jan',
        'Jannik',
        'Jason',
        'Jayden',
        'Joel',
        'Johann',
        'Johannes',
        'John',
        'Jona',
        'Jonas',
        'Jonathan',
        'Joshua',
        'Julian',
        'Julius',
        'Justus',
        'Karl',
        'Kilian',
        'Konstantin',
        'Lennard',
        'Lenni',
        'Lennox',
        'Leo',
        'Leon',
        'Leonard',
        'Levi',
        'Levin',
        'Liam',
        'Lian',
        'Linus',
        'Luis',
        'Luka',
        'Lukas',
        'Maksim',
        'Male',
        'Malte',
        'Marlon',
        'Mats',
        'Matteo',
        'Matthis',
        'Max',
        'Maximilian',
        'Michael',
        'Mika',
        'Milan',
        'Mohammed',
        'Moritz',
        'Nick',
        'Niclas',
        'Niko',
        'Nils',
        'Noah',
        'Noel',
        'Ole',
        'Oscar',
        'Paul',
        'Phil',
        'Philipp',
        'Raphael',
        'Robin',
        'Sam',
        'Samuel',
        'Sebastian',
        'Simon',
        'Theo',
        'Till',
        'Tim',
        'Timo',
        'Tobias',
        'Tom',
        'Tony',
        'Tyler',
        'Valentin',
        'Vincent',
        'Yannis'
    ],

    Netherlands: [
        'Daan',
        'Bram',
        'Sem',
        'Lucas',
        'Milan',
        'Levi',
        'Luuk',
        'Thijs',
        'Jayden',
        'Tim',
        'Finn',
        'Stijn',
        'Thomas',
        'Lars',
        'Ruben',
        'Jesse',
        'Noah',
        'Julian',
        'Max',
        'Liam',
        'Mees',
        'Sam',
        'Sven',
        'Gijs',
        'Luca',
        'Teun',
        'Tijn',
        'Siem',
        'Mats',
        'Jens',
        'Benjamin',
        'Adam',
        'Ryan',
        'Jan',
        'Floris',
        'David',
        'Olivier',
        'Cas',
        'Tygo',
        'Dylan',
        'Ties',
        'Tom',
        'Pepijn',
        'Daniël',
        'Hugo',
        'Thijmen',
        'Dean',
        'Boaz',
        'Nick',
        'Willem',
        'Roan',
        'Dex',
        'Niels',
        'Guus',
        'Stan',
        'Koen',
        'Mohamed',
        'Joep',
        'Johannes',
        'Jurre',
        'Pim',
        'Niek',
        'Robin',
        'Bas',
        'Rayan',
        'Damian',
        'Jelle',
        'Noud',
        'Pieter',
        'Vince',
        'Dani',
        'Joris',
        'Jason',
        'Timo',
        'Mick',
        'Quinten',
        'Joshua',
        'Simon',
        'Tobias',
        'Kyan',
        'Hidde',
        'Mohammed',
        'Jack',
        'Quinn',
        'Rens',
        'Samuel',
        'Alexander',
        'Hendrik',
        'Xavi',
        'Joey',
        'Fabian',
        'Justin',
        'Keano',
        'Cornelis',
        'Fedde',
        'Casper',
        'Morris',
        'Mike'
    ],

    Sweden: [
        'Lucas',
        'William',
        'Oscar',
        'Oliver',
        'Hugo',
        'Charlie',
        'Liam',
        'Alexander',
        'Axel',
        'Elias',
        'Filip',
        'Viktor',
        'Vincent',
        'Leo',
        'Ludvig',
        'Olle',
        'Anton',
        'Arvid',
        'Emil',
        'Melvin',
        'Edvin',
        'Erik',
        'Gustav',
        'Isak',
        'Theo',
        'Elliot',
        'Nils',
        'Noah',
        'Alfred',
        'Adam',
        'Theodor',
        'Albin',
        'Leon',
        'Benjamin',
        'Sixten',
        'Adrian',
        'Max',
        'Viggo',
        'Wilmer',
        'Valter',
        'Malte',
        'Gabriel',
        'Melker',
        'Alvin',
        'Simon',
        'Milo',
        'Kevin',
        'Jack',
        'Casper',
        'Jacob',
        'Mohamed',
        'Jonathan',
        'Felix',
        'Love',
        'Josef',
        'Noel',
        'Carl',
        'Harry',
        'Ville',
        'Loke',
        'Sebastian',
        'Vidar',
        'Samuel',
        'August',
        'Rasmus',
        'Linus',
        'Sigge',
        'Sam',
        'David',
        'Ebbe',
        'Wilhelm',
        'Frank',
        'Milton',
        'Elton',
        'Elvin',
        'Vilgot',
        'Ivar',
        'Colin',
        'Tage',
        'Neo',
        'Joel',
        'Aron',
        'Elis',
        'John',
        'Daniel',
        'Maximilian',
        'Eddie',
        'Hjalmar',
        'Henry',
        'Louie',
        'Edward',
        'Matteo',
        'Julian',
        'Svante',
        'Hampus',
        'Mio',
        'Alex',
        'Tim',
        'Tor'
    ],

    Norway: [
        'Alf',
        'Andreas/Anders/Andre',
        'Arne',
        'Arvid',
        'Balder',
        'Bjørn/Bjørnar',
        'Hans',
        'David',
        'Mathias',
        'Brage',
        'Ivan',
        'Alfred',
        'Filip/Filipp',
        'Benjamin',
        'Gabriel',
        'Helge',
        'Inge',
        'Odd',
        'Pål',
        'Jørn',
        'Erlend/Erling/Elling',
        'Svein/Sveinung',
        'Jonas',
        'Henrik/Henry',
        'Tor(d)/Torbjørn/Torgrim/Torleif/Torstein',
        'Frode',
        'Emil',
        'William',
        'Leif',
        'Gard',
        'Alexander',
        'Isak',
        'Jakob',
        'John',
        'Klaus',
        'Dag/Dag-Erik',
        'Kåre',
        'Kenneth',
        'Finn',
        'Kjartan',
        'Kjell',
        'Knut',
        'Harald/Gustav',
        'Kristen/Kristian',
        'Kristoffer',
        'Nils',
        'Sjur',
        'Sondre',
        'Lukas',
        'Lasse',
        'Lars',
        'Liam',
        'Brede',
        'Petter',
        'Markus',
        'Martin',
        'Marius',
        'Sindre',
        'Amund',
        'Oliver',
        'Eirik/Erik',
        'Fredrik',
        'Leander',
        'Leon',
        'Adrian',
        'Per',
        'Remi',
        'Michael/Mikkel',
        'Karl',
        'Ola/Ole',
        'Elias',
        'Aslak/Asle',
        'Ruben',
        'Rune',
        'Sander',
        'Ulrik',
        'Simen',
        'Sigurd',
        'Sigve',
        'Magnus',
        'Bård',
        'Gaute',
        'Kjetil',
        'Jan',
        'Frank',
        'Bernhard',
        'Oscar',
        'Stein',
        'Egil',
        'Stig',
        'Sverre',
        'Tobias',
        'Tom/Tommy',
        'Thomas',
        'Truls',
        'Jarle',
        'Victor/Viktor',
        'Jens',
        'Jo/Johannes/Josef',
        'Allan'
    ],

    Denmark: [
        'Tobias',
        'Daniel',
        'Mads',
        'Simon',
        'Frederik',
        'Andreas',
        'Christian',
        'Martin',
        'Emil',
        'Peter',
        'Oliver',
        'Christoffer',
        'Anders',
        'Erik ;D',
        'Mathias',
        'Kristian',
        'Lucas',
        'Magnus',
        'Jonas',
        'Morten',
        'Lukas',
        'Rasmus',
        'Casper',
        'Jeppe',
        'Nicolaj',
        'Buster',
        'Patrick',
        'Nicolas',
        'Jesper',
        'Nikolaj',
        'Sebastian',
        'Thomas',
        'Mikkel',
        'Marcus',
        'Nicolai',
        'Nicklas',
        'Holger',
        'Jonathan',
        'Steven',
        'Kevin',
        'Philip',
        'Muhammed',
        'Henrik',
        'Ramoo',
        'Ulrick',
        'David',
        'Erik',
        'Raoul',
        'Alexander',
        'Jack',
        'Jiar',
        'Lasse',
        'Mustafa',
        'Idoménée',
        'Jørgen',
        'Niclas',
        'Per',
        'Krisrian',
        'Chris',
        'Leonard',
        'Krzysztof',
        'Rara',
        'Don Ngole',
        'Hayden',
        'Jacob',
        'Søren K. : )',
        'Ricky',
        'Nico',
        'Andrea',
        'Legind',
        'John',
        'Jannik',
        'F.V.K.',
        'Valdemar',
        'Rolan',
        'Bo',
        'Harry',
        'Thor',
        'Rodrigo',
        'Tom',
        'Mtn',
        'Mikael',
        'Kingsly',
        'Sune',
        'Silas',
        'Oscar',
        'Noah',
        'Wiso',
        'Claus',
        'Mohammed',
        'Ole',
        'Ken',
        'Ayoe',
        'Erland',
        'Luxnux',
        'Akif',
        'Ramazan',
        'Otto',
        'Nchami',
        'Adnan'
    ],

    UK: [
        'Harry',
        'Oliver',
        'Jack',
        'Charlie',
        'Jacob',
        'Thomas',
        'Alfie',
        'Riley',
        'William',
        'James',
        'Joshua',
        'George',
        'Ethan',
        'Noah',
        'Samuel',
        'Daniel',
        'Oscar',
        'Muhammad',
        'Max',
        'Archie',
        'Leo',
        'Joseph',
        'Tyler',
        'Henry',
        'Mohammed',
        'Alexander',
        'Lucas',
        'Dylan',
        'Isaac',
        'Logan',
        'Benjamin',
        'Mason',
        'Jake',
        'Harrison',
        'Finley',
        'Edward',
        'Freddie',
        'Adam',
        'Jayden',
        'Zachary',
        'Sebastian',
        'Lewis',
        'Ryan',
        'Theo',
        'Luke',
        'Matthew',
        'Harley',
        'Harvey',
        'Toby',
        'Liam',
        'Arthur',
        'Michael',
        'Callum',
        'Tommy',
        'Jenson',
        'Nathan',
        'Bobby',
        'Mohammad',
        'David',
        'Connor',
        'Luca',
        'Charles',
        'Jamie',
        'Frankie',
        'Kai',
        'Alex',
        'Blake',
        'Reuben',
        'Aaron',
        'Dexter',
        'Jude',
        'Stanley',
        'Leon',
        'Elliot',
        'Gabriel',
        'Ollie',
        'Louie',
        'Aiden',
        'Cameron',
        'Louis',
        'Owen',
        'Finlay',
        'Elijah',
        'Frederick',
        'Hugo',
        'Caleb',
        'Taylor',
        'Sonny',
        'Seth',
        'Kyle',
        'Elliott',
        'Robert',
        'Kian',
        'Theodore',
        'Kayden',
        'Rhys',
        'Rory',
        'Bailey',
        'Evan'
    ],

    Italy: [
        'Alberto',
        'Ale',
        'Alessandro',
        'Alessio',
        'Alex',
        'Andrea',
        'Andrew',
        'Angelo',
        'Antonio',
        'Carlo',
        'Carmelo',
        'Christian',
        'Ciro',
        'Claudio',
        'Cristian',
        'Cristiano',
        'Cyril',
        'Dado',
        'Daniel',
        'Daniele',
        'Dario',
        'Davide',
        'Denis',
        'Diego',
        'Domenico',
        'Edoardo',
        'Emanuele',
        'Enrico',
        'Ettore',
        'Fabio',
        'Fabrizio',
        'Federico',
        'Filippo',
        'Flavio',
        'Francesco',
        'Gabriel',
        'Gabriele',
        'Gavino',
        'Giacomo',
        'Giancarlo',
        'Gianluca',
        'Gianluigi',
        'Gianmarco',
        'Giorgio',
        'Giovanni',
        'Giulio',
        'Giuseppe',
        'Guido',
        'Ivan',
        'Kevin',
        'Khadim',
        'Leo',
        'Leonardo',
        'Lorenzo',
        'Luca',
        'Luciano',
        'Lucio',
        'Luigi',
        'Manuel',
        'Marco',
        'Mario',
        'Mark',
        'Martin',
        'Matteo',
        'Mattia',
        'Maurizio',
        'Mauro',
        'Michael',
        'Michele',
        'Mirko',
        'Nicola',
        'Nicolas',
        'Nicolò',
        'Omar',
        'Paolo',
        'Pasquale',
        'Pier',
        'Piero',
        'Pietro',
        'Raffaele',
        'Riccardo',
        'Roberto',
        'Rosario',
        'Salvatore',
        'Samuel',
        'Samuele',
        'Saverio',
        'Stefano',
        'Thomas',
        'Tiziano',
        'Tom',
        'Tommaso',
        'Tony',
        'Umberto',
        'Vincenzo',
        'Vito',
        'Vittorio'
    ],

    France: [
        'Thomas',
        'Julien',
        'Nicolas',
        'Alexandre',
        'Maxime',
        'Kevin',
        'Guillaume',
        'Romain',
        'Antoine',
        'Quentin',
        'Clément',
        'Pierre',
        'Jeremy',
        'Anthony',
        'Florian',
        'Mathieu',
        'Valentin',
        'Vincent',
        'Alexis',
        'Adrien',
        'Benjamin',
        'Lucas',
        'Paul',
        'Sébastien',
        'Hugo',
        'Théo',
        'Arthur',
        'Alex',
        'Baptiste',
        'Matthieu',
        'Jonathan',
        'David',
        'Loic',
        'Thibault',
        'Arnaud',
        'Jordan',
        'Aurélien',
        'Damien',
        'Dylan',
        'Mickael',
        'Simon',
        'Corentin',
        'Rémi',
        'François',
        'Cédric',
        'Nathan',
        'Florent',
        'Jérôme',
        'Victor',
        'Axel',
        'Louis',
        'Fabien',
        'Leo',
        'Robin',
        'Tom',
        'Benoit',
        'Raphaël',
        'Charles',
        'Sylvain',
        'Thibaut',
        'Bastien',
        'Samuel',
        'Laurent',
        'Yann',
        'Cyril',
        'Olivier',
        'Enzo',
        'Marc',
        'Martin',
        'Gaetan',
        'Xavier',
        'Maxence',
        'Stephane',
        'Steven',
        'Tristan',
        'Tony',
        'Jean',
        'Rémy',
        'Max',
        'Dorian',
        'Dimitri',
        'Christophe',
        'Mehdi',
        'Yoann',
        'Gabriel',
        'Ludovic',
        'William',
        'Etienne',
        'Franck',
        'Jérémie',
        'Erwan',
        'Geoffrey',
        'Morgan',
        'Jean-Baptiste',
        'Grégory',
        'Christopher',
        'Philippe',
        'Jules',
        'Michael',
        'Mohamed'
    ],

    Russia: [
        'Dima',
        'Nikita',
        'Alex',
        'Vlad',
        'Andrey',
        'Alexander',
        'Artem',
        'Dmitry',
        'Sergey',
        'Max',
        'Anton',
        'Ivan',
        'Sasha',
        'Roman',
        'Kirill',
        'Pavel',
        'Andrew',
        'Oleg',
        'Ruslan',
        'Egor',
        'Maxim',
        'Danil',
        'Vladislav',
        'Ilya',
        'Kostya',
        'Denis',
        'Alexandr',
        'Vadim',
        'Konstantin',
        'Pasha',
        'Igor',
        'Vladimir',
        'Arthur',
        'Andrei',
        'Daniel',
        'Zhenya',
        'Daniil',
        'Vitaliy',
        'Mark',
        'Gleb',
        'Sergei',
        'Vova',
        'Kolya',
        'Rafit',
        'Dan',
        'Artyom',
        'Mansur',
        'Alexey',
        'Yaroslav',
        'Misha',
        'Boris',
        'Mikhail',
        'Yegor',
        'Slava',
        'Stanislav',
        'Anatoly',
        'Rodion',
        'Aymeric',
        'Paul',
        'Matvey',
        'Aleksey',
        'Leonid',
        'Bogdan',
        'Adam',
        'Vasiliy',
        'Grisha',
        'Zahar',
        'Aleksei',
        'Artur',
        'George',
        'Bekhan',
        'Arsenii',
        'Nick',
        'Thierry',
        'Ekaterina',
        'Aleksandr',
        'Ilja',
        'Victor',
        'Fedya',
        'Nikolay',
        'David',
        'Evgeny',
        'Michael',
        'Artiom',
        'Dmitriy',
        'Roma',
        'Ilia',
        'Mihail',
        'Maksim',
        'Valera',
        'Danila',
        'Eugene',
        'Valentin',
        'Serge',
        'Stas',
        'Dawydow Oieg',
        'Nikolai',
        'Yura',
        'Nastya',
        'Anya'
    ],

    Ukraine: [
        'Sasha',
        'Alex',
        'Dima',
        'Vova',
        'Denis',
        'Denys',
        'Stas',
        'Paul',
        'Alexander',
        'Igor',
        'Kostya',
        'Anton',
        'Mike',
        'Vadim',
        'Vlad',
        'Vitaly',
        'Andrii',
        'Michael',
        'Ivan',
        'Illia',
        'Alexandr',
        'Yaroslav',
        'Artem',
        'Mark',
        'Mykola',
        'Vika',
        'Sergiy',
        'Sergey',
        'Oxy',
        'Nick',
        'Eugeny',
        'Ceriu',
        'Taras',
        'Saleh',
        'Michel',
        'Bohdan',
        'Cameroun',
        'Franck',
        'Martia',
        'Zakhar',
        'Vas',
        'Maxim',
        'Nataly',
        'Tom',
        'Maxime',
        'Danil',
        'Gosha',
        'Serg',
        'Aloyna',
        'Wiki',
        'Tolyan',
        'Nata',
        'Andriy',
        'Sviatoslav',
        'Vitaliy',
        'Ken',
        'Oleg Aka Ray Aka Scn',
        'Nikita',
        'Oleg',
        'Maks',
        'Oleg -_-',
        'David',
        'Miha',
        'Akhmed',
        'Vladislav',
        'Natalia',
        'Yuriy',
        'Mustafa',
        'Vitali',
        'Kpoulomou',
        'Vsevolod',
        'Ruslan',
        'Daniel',
        'Yan',
        'Yegor',
        'Bogdan',
        'Inna',
        'Svyrydiuk Yuliia',
        'Konstans',
        'Toha',
        'Egor',
        'Tony',
        'Ura',
        'Robert',
        'Ìùìô',
        'Myroslav',
        'Dzhey',
        'Àíäðåé',
        'Rostik',
        'Grisha',
        'Roma',
        'Max',
        'Andrey',
        'Oleksij',
        'Nick-Kun',
        'Dany',
        'Ole',
        'Dymytro',
        'Viki',
        'Dmytro'
    ],

    Poland: [
        'Adam',
        'Adrian',
        'Aleksander',
        'Andrzej',
        'Antoni',
        'Arkadiusz',
        'Artur',
        'Bartlomiej',
        'Bartosz',
        'Bogdan',
        'Cezary',
        'Damian',
        'Daniel',
        'Dariusz',
        'Dawid',
        'Dominik',
        'Filip',
        'Franciszek',
        'Grzegorz',
        'Hubert',
        'Igor',
        'Jacek',
        'Jakub',
        'Jan',
        'Juliusz',
        'Kacper',
        'Kamil',
        'Karol',
        'Konrad',
        'Krystian',
        'Krzysztof',
        'Leszek',
        'Lukasz',
        'Maciej',
        'Marcel',
        'Marcin',
        'Marek',
        'Mateusz',
        'Michal',
        'Mikolaj',
        'Milosz',
        'Norbert',
        'Patryk',
        'Pawel',
        'Piotr',
        'Przemyslaw',
        'Rafal',
        'Robert',
        'Sebastian',
        'Stanislaw',
        'Sylwester',
        'Szymon',
        'Tomasz',
        'Wiktor',
        'Wladyslaw',
        'Wojciech'
    ],

    Arab: [
        'Abbas',
        'Abdel',
        'Abdou',
        'Abdullah',
        'Achref',
        'Adel',
        'Ahmed',
        'Aissa',
        'Alem',
        'Alexshi',
        'Ali',
        'Amed',
        'Amir',
        'Amumu',
        'Andro',
        'Aniss',
        'Arezki',
        'Ari',
        'Assem',
        'Aymen',
        'Azedine',
        'Aziz',
        'Bassel',
        'Bedario',
        'Brahim',
        'Dadi',
        'David',
        'Djamal',
        'Dods',
        'Elmalky',
        'Elyes',
        'Enam',
        'Eslam',
        'Fares',
        'Farouk',
        'Fateh',
        'Faycal',
        'Firas',
        'Fouad',
        'Freedy',
        'Gohar',
        'Habib',
        'Hachemi',
        'Haisam',
        'Hakim',
        'Halim',
        'Hamid',
        'Hamza',
        'Hanibal',
        'Hany',
        'Hazem',
        'Hesso',
        'Hichem',
        'Hocine',
        'Houssem',
        'Ibty',
        'Idir',
        'Imed',
        'Islam',
        'Iso',
        'Juba',
        'Kader',
        'Kamel',
        'Karim',
        'Khaled',
        'Loay',
        'Lotfi',
        'Lounis',
        'Lyes',
        'Madjid',
        'Madou',
        'Magdi',
        'Mahdi',
        'Mahmoud',
        'Malek',
        'Malik',
        'Mando',
        'Marawan',
        'Massinissa',
        'Mazen',
        'Medo',
        'Mehdi',
        'Mhmod',
        'Mido',
        'Moemen',
        'Mohab',
        'Mohamed',
        'Mohammed',
        'Mohaned',
        'Mohmed',
        'Momo',
        'Mostafa',
        'Mouloud',
        'Moundji',
        'Mourad',
        'Muhammad',
        'Musrawy',
        'Mustafa',
        'Nabil',
        'Nacer',
        'Nader',
        'Nadir',
        'Nasr',
        'Nassim',
        'Nazim',
        'Nazir',
        'Nino',
        'Omar',
        'Omer',
        'Osama',
        'Osman',
        'Rabah',
        'Rachid',
        'Ramy',
        'Rayan',
        'Romuald',
        'Said',
        'Salah',
        'Salim',
        'Sami',
        'Samir',
        'Samy',
        'Sélim',
        'Sherif',
        'Simo',
        'Sofiane',
        'Srikanth',
        'Syphax',
        'Vegas',
        'Walid',
        'Walid',
        'Wassim',
        'Yacine',
        'Yahia',
        'Yasseen',
        'Yasser',
        'Yassine',
        'Younes',
        'Youssef',
        'Zahir',
        'Zakaria',
        'Zaki',
        'Zeyad',
        'Zizo',
        'Zoheir'
    ],

    Pakistan: [
        'Ali',
        'Bilal',
        'Abdul',
        'Muhammad',
        'Mohammad',
        'Adnan',
        'Ibrahim',
        'Usman',
        'Imran',
        'Farooq',
        'Tariq',
        'Sunny',
        'Awais',
        'Faisal',
        'Waqas',
        'Hamza',
        'Khan',
        'Tahir',
        'Vijay',
        'Qaiser',
        'Sameer',
        'Hammad',
        'Babar',
        'Wisal',
        'Subhan',
        'Naeem',
        'Kashif',
        'Zeeshan',
        'Farid',
        'Hadier',
        'Munir Ahmad',
        'Sheikh',
        'Abbas',
        'Hakka',
        'Abdullah',
        'Safder',
        'Maqbool Ahmed',
        'Darya Khan Panhyar',
        'Bilawal',
        'Sahir',
        'Farrukh',
        'Hassan',
        'Saad',
        'Ihtisham',
        'Naveed',
        'Wasay',
        'Danial',
        'Noman',
        'Abd Ul Rehman',
        'Ibadshah',
        'Sameer Jaan',
        'Shani',
        'Zarbakhat',
        'Fazal',
        'Babu',
        'Abrar Uz Zaman',
        'Orchid Oliver',
        'Amin',
        'Xain',
        'Sheryar',
        'M. Usman',
        'Gohar',
        'Zahmah',
        'Sikandar',
        'Salman',
        'Aqasha',
        'Waleed',
        'M.Mussarat Ali',
        'Aamir',
        'Azhar',
        'Adrian',
        'Shehzad',
        'Abdul Majid',
        'Farhan Dashti',
        'Usama',
        'Faizan',
        'Daniyal',
        'Farid Ali',
        'Danish',
        'Fida',
        'Addy',
        'Foreigner',
        'Moon',
        'Khasham',
        'Amir',
        'Atif Raza',
        'Wajahat',
        'Muhammad Nasir',
        'Atif',
        'Abdur Rafi',
        'Zain',
        'Rehan',
        'Adnan Magsi',
        'Ghazi',
        'Hellboy',
        'Asif',
        'Zian',
        'Adi',
        'Aatif',
        'Ghani'
    ],

    India: [
        'Rahul',
        'Aditya',
        'Abhishek',
        'Mahesh',
        'Shyam',
        'Yash',
        'Kumar',
        'Arjun',
        'Deepak',
        'Raj',
        'Amit',
        'Abhinav',
        'Rohit',
        'Ankur',
        'Parth',
        'Palash',
        'Rakesh',
        'Mayank',
        'Rishabh',
        'Sahil',
        'Dinesh',
        'Sunny',
        'Siddharth',
        'Nitin',
        'Akash',
        'Omkar',
        'Arun',
        'Nishant',
        'Neeraj',
        'Prathamesh',
        'Ashwin',
        'Shaan',
        'Varun',
        'Karan',
        'Ajith',
        'Manoj',
        'Ajay',
        'Manish',
        'Rohan',
        'Soham',
        'Arka',
        'Shubham',
        'Prashant',
        'Girish',
        'Prateek',
        'Ramanan',
        'Suresh',
        'Midhun',
        'Raju',
        'Vishnu',
        'Jay',
        'Vijay',
        'Anupam',
        'Akshay',
        'Ashutosh',
        'Pankaj',
        'Raunak',
        'Akhil',
        'Anish',
        'Deepro',
        'Aryan',
        'Sam',
        'Ram',
        'Kapil',
        'Ashish',
        'Mohit',
        'Tauseen',
        'Ankit',
        'Harish',
        'Pranav',
        'Himanshu',
        'Anirudh',
        'Kaustubh',
        'Nikhil',
        'Jatin',
        'Dhruv',
        'Alok',
        'Prakash',
        'Rajeev',
        'Abhi',
        'Krishna',
        'Aaditya',
        'Tushar',
        'Vibhor',
        'Rajesh',
        'Shivam',
        'Nitesh',
        'Karthik',
        'Salman',
        'Rajee',
        'Viraj',
        'Anuj',
        'Darshan',
        'Ujan',
        'Apoorv',
        'Arijit',
        'Ryan',
        'Lipun',
        'Shail',
        'Sameer'
    ],

    Philippines: [
        'Rodrigo',
        'Melvin',
        'Mark',
        'Edenjevy',
        'Ryan',
        'Daniel',
        'Martin',
        'Joshua',
        'Louis',
        'Joseph',
        'Kyle',
        'Felix',
        'Chrislann',
        'Jeffrey',
        'James',
        'Ian',
        'Lee',
        'Christian',
        'Lester',
        'Jeff',
        'Mark John',
        'Bryan',
        'Keith Lorenz',
        'Louie Johnson',
        'Gila Punya Orang',
        'Dale',
        'Ramon Santos',
        'Kyle L.',
        'Frechly',
        'Ced',
        'Fau',
        'Josh',
        'Jan-Claude',
        'Ching',
        'Geo',
        'Monty',
        'Bhenly',
        'Jp',
        'Seol',
        'Mykeey',
        'Adriano',
        'Jefferson',
        'Darene',
        'Roberto',
        'Vince',
        'Kris Louise',
        'Jack',
        'Virgilio',
        'Jonelver',
        'Lou',
        'King Arthur',
        'Juriz',
        'Neil Bryan',
        'Francisco',
        'Paolo',
        'Jay Marnel',
        'Kenneth',
        'Jhonel',
        'Brent',
        'Ian Benedict',
        'Eras',
        'Adrian',
        'Patrick',
        'Will',
        'Angelou',
        'Pablo',
        'Nicholas',
        'Jericho',
        'Vincent',
        'Brian Vincent',
        'Kirk',
        'Bradley Myles',
        'Reeinald',
        'Christian Rize',
        'Julio',
        'Alvin',
        'Marcus',
        'Carlo',
        'Jake',
        'Nelvin',
        'Noah',
        'Ralph',
        'Dandy Park',
        'Calyx',
        'Neli',
        'Rico',
        'Monster',
        'Jules',
        'John',
        'Rick',
        'William',
        'Mayi',
        'Khalid',
        'Marc',
        'Ronald Kevin',
        'Mark Lorenz',
        'Hal',
        'Rey',
        'Jed',
        'Josuer'
    ],

    Indonesia: [
        'Aditya',
        'Ahmad',
        'Farel',
        'Jonathan',
        'Anas',
        'Haliim',
        'Rizki',
        'Andre',
        'Kevin',
        'Rio',
        'Angga',
        'Denny',
        'Ramadhan',
        'Ari',
        'Prendy',
        'Rifqi',
        'Henry',
        'Hansen',
        'William',
        'Septian',
        'Andreas',
        'Andra',
        'Ilham',
        'Fauzi',
        'Yoga',
        'Zulfikar',
        'Euan',
        'Rafi',
        'Hilman',
        'Adesta',
        'Gilang',
        'Adit',
        'Raditya',
        'Hengki',
        'Andy',
        'Adrian',
        'Timoty',
        'Arif',
        'Hardi',
        'Sabil',
        'Hans',
        'Fajar',
        'Muhammad',
        'Arief',
        'Firman',
        'Akmal',
        'Fadhlan',
        'Endang',
        'Ricky',
        'Rama',
        'Felix',
        'Reza',
        'Aldo',
        'Sakti Mikaeel',
        'Jonah',
        'Budi',
        'Ligo',
        'James',
        'Bryan',
        'Samuel Reinhard',
        'Yudianto',
        'Daniel',
        'Plato',
        'Sahdan',
        'Ogi',
        'Baim',
        'Fadlan',
        'Manshur',
        'Taufiq Rizaldi',
        'Yohanes',
        'Ade',
        'Armada',
        'Ezekiel',
        'Haris',
        'Marwan',
        'Shafwan',
        "Wisnu'S",
        'Ycl',
        'Joshua Christian',
        'Nicholas',
        'Arga',
        'Darren',
        'Samuel',
        'Jokovic',
        'Bagas Tri',
        'Sandy',
        'Matheus',
        'Alessandro',
        'Feras',
        'Rudi',
        'David',
        'Hidayat',
        'Irfan',
        'Ahnaf Hilman',
        'Mellki',
        'Yusfa',
        'Jimmy',
        'Eri',
        'Kukuh',
        'Arsyad'
    ],

    Thailand: [
        'Nattapong',
        'Tanawat',
        'Benz',
        'Sirichai',
        'Gan',
        'Art',
        'Teerapat',
        'Boom',
        'Thawatchai',
        'New',
        'Chatchai',
        'Pond',
        'David',
        'Ice',
        'Kachawuth',
        'Chanathip',
        'Golf',
        'Thanakorn',
        'Andy',
        'Big',
        'Tanakorn',
        'Tanaphon',
        'Bank',
        'Nice',
        'Dew',
        'Werawat',
        'Joe',
        'Earth',
        'Nut',
        'Robin',
        'Sirawit',
        'Anurat',
        'Krit',
        'Piyawat',
        'Surasak',
        'Wuttichai',
        'Ball',
        'Nithirot',
        'Kiadtisak',
        'Boonrit',
        'Chinnawat',
        'Nattaphong',
        'Pakkapong',
        'Eric Lin',
        'Man',
        'Watcharapong',
        'Natthapon',
        'Narawit',
        'James',
        'Thanapon',
        'Lucas',
        'Nattawut',
        'Siripoom Koonti',
        'Nattawat',
        'Pannatorn',
        'Noppadol',
        'Narupong',
        'Sakchai',
        'Alex',
        'Wutthichai',
        'Por',
        'Ex',
        'Pakawat',
        'Kritsada',
        'Rodger',
        'Wayne',
        'Patcharapon',
        'Thanakit',
        'Wittawin',
        'Timon',
        'Jongjaroen',
        'Puii',
        'Somrak',
        'Settawut',
        'Palm',
        'Jennarong',
        'Supakit',
        'Watchara',
        'Aphiwat',
        'William',
        'Prommin',
        'Supakarn',
        'Kittipit',
        'Patiwat',
        'Parin',
        'Kittinan',
        'Silanon',
        'Boonyarit',
        'Phongnitit',
        'Saharut',
        'Natth',
        'Konlawat',
        'Nakharin',
        'Samart',
        'Seegame',
        'Aekkaluck',
        'Komsan'
    ],

    China: [
        'Gao Lang',
        'Hao Xuan',
        'Jia Yu',
        'Jie Wei',
        'Jun Lang',
        'We Qiang',
        'Xiong Yi',
        'Xu Jie',
        'Yu Xuan',
        'Ying Jie',
        'Yue Bing',
        'Hao Jian',
        'Xi Hua',
        'Hou Ya',
        'De Hou',
        'De Hui',
        'De Mao',
        'De Mao',
        'De Xin',
        'He Xuan',
        'Li Cheng',
        'Ming Cheng',
        'Ming Du',
        'Ming Yuan',
        'Lang Zi',
        'Ming Zhe',
        'Wei Cheng',
        'Xi Mao',
        'Xi Tai',
        'Xi Yuan',
        'Xuan Lang',
        'Xuan Zhan',
        'Ya Chan',
        'Ya Liang',
        'Yi Zhe',
        'Yu Xuan',
        'Zhe Ming',
        'Zhe Yu',
        'Jia Zhi',
        'Wei Yu',
        'Bo Chao',
        'Bo Wen',
        'Gao Jun',
        'Gao Zhuo',
        'Hong Wen',
        'Jian Gong',
        'Jie Hao',
        'Jie Mao',
        'Jun Wei',
        'Jun Zhe',
        'Jun Zhi',
        'Du Yuan',
        'Shen Jie',
        'Cheng Du',
        'Wei Huan',
        'Wen Jie',
        'Yan Bo',
        'Xiong Bo',
        'Xu Yao',
        'Zhe Han',
        'Change Miao',
        'Gao Suan',
        'Wo Ran',
        'Hao Xiao',
        'Li Xin',
        'Ming Can',
        'Ming Xu',
        'Ming Xu',
        'Ming Yao',
        'Peng Xuan',
        'Wei Bo',
        'Xin Xin',
        'Xin Xi',
        'Yi Tong',
        'Yue Ze',
        'Ze Yang',
        'Kai Ji',
        'He Tai',
        'Hong Tao',
        'Jian Bai',
        'Jin Peng',
        'Jing Qi',
        'Kai Ze',
        'Kai Rui',
        'Kang Yu',
        'Qing Yi',
        'Shao Hui',
        'Tian Lei',
        'Tian Yu',
        'Wei Qi',
        'Xin Yi',
        'Xin Rong',
        ' Xin Peng',
        'Yi Chang',
        'Yi He',
        'Yi Ning',
        'Yi Yue',
        'Yu Qi',
        'Yuan Xiang'
    ],

    Korea: [
        'Kim',
        'Lee',
        'Park',
        'Choi',
        'Kang',
        'Jang',
        'Jung',
        'Shin',
        'Lim',
        'Song',
        'Han',
        'Jeong',
        'Cho',
        'Yang',
        'Kwon',
        'Oh',
        'Hong',
        'Jin',
        'Jun',
        'Seo',
        'Hwang',
        'Jo',
        'Jeon',
        'Min',
        'David',
        'Yoon',
        'John',
        'Son',
        'Yoo',
        'Daniel',
        'Moon',
        'Ahn',
        'Yun',
        'Hyun',
        'Ko',
        'Joon',
        'Bae',
        'Kevin',
        'Jay',
        'Alex',
        'Young',
        'Nam',
        'Junho',
        'James',
        'Sim',
        'Jason',
        'An',
        'Andrew',
        'Dong Hyun',
        'Ha',
        'June',
        'Sung',
        'Ryu',
        'Paul',
        'Harry',
        'Baek',
        'Eric',
        'Minsu',
        'Cha',
        'Kwak',
        'Andy',
        'Chris',
        'Chan',
        'Im',
        'Jack',
        'Won',
        'Jihun',
        'Danny',
        'Seong',
        'Sam',
        'Sang Min',
        'Minwoo',
        'Yu',
        'Minho',
        'Ji Hoon',
        'Joo',
        'Joseph',
        'Go',
        'Sin',
        'Peter',
        'Michael',
        'Justin',
        'Woo',
        'Jake',
        'Sean',
        'Shim',
        'Chae',
        'Woojin',
        'Donghyun',
        'Jongmin',
        'Jaehyun',
        'Henry',
        'Tony',
        'Sun',
        'You',
        'Ji',
        'Seung Hyun',
        'Sung Min',
        'Seungmin',
        'Jihoon'
    ],

    Japan: [
        'Yuki',
        'Takuya',
        'Naoki',
        'Ken',
        'Takumi',
        'Kazuki',
        'Shota',
        'Kazu',
        'Hiro',
        'Keita',
        'Hiroki',
        'Taka',
        'Daiki',
        'Naoya',
        'Shun',
        'Ren',
        'Kento',
        'Yuta',
        'Taro',
        'Masa',
        'Kei',
        'Yuuki',
        'Daisuke',
        'Yu',
        'Yusuke',
        'Toshi',
        'Shuhei',
        'Brad',
        'Tom',
        'You',
        'Tatsuya',
        'Kouki',
        'Suzuki',
        'Makoto',
        'Hiroaki',
        'Taku',
        'Yuya',
        'Jack',
        'David',
        'Eito',
        'Evan',
        'Dai',
        'Yuto',
        'Haruki',
        'Ryo',
        'Atsushi',
        'Ryota',
        'Daichi',
        'Hiroshi',
        'Kenta',
        'Sato',
        'Issei',
        'Yuma',
        'Shinya',
        'Kazuya',
        'Shinji',
        'Masaki',
        'Naoto',
        'Shuto',
        'Henry',
        'Kotaro',
        'Kyohei',
        'William',
        'Yuu',
        'Yuichiro',
        'Aki',
        'Kouhei',
        'Choko',
        'Satoshi',
        'Takaya',
        'Kiyoshi',
        'Tomoki',
        'Kanako',
        'Mm',
        'Daniel',
        'Kazuo',
        'Haru',
        'Taichi',
        'Haruhito',
        'Hiko-Ki',
        'Taiyo',
        'Wataru',
        'Orimitsu',
        'Tomo',
        'Akira',
        'Areshi',
        'Gatti',
        'Hayashi',
        'Jose',
        'Kiyomori',
        'Sou',
        'Oono',
        'Wada',
        'Takeru',
        'Takeshi',
        'Adam',
        'Itasan',
        'Berry',
        'Hikaru',
        'Purna'
    ],

    Africa: [
        'Abebe',
        ' Abel',
        ' Abidemi',
        ' Abimbola',
        ' Abioye',
        ' Abrafo',
        ' Adegoke',
        ' Baas',
        ' Baba',
        ' Babajide',
        ' Babatunde',
        ' Bamidele',
        ' Bapoto',
        ' Baraka',
        ' Chakide',
        ' Chibueze',
        ' Chibuzo',
        ' Chidi',
        ' Dabulamanzi',
        ' Dada',
        ' Dafari',
        ' Dambudzo',
        ' Dayo',
        ' Dede',
        ' Delmar',
        ' Dosu',
        ' Dubaku',
        ' Dube',
        ' Ebele',
        ' Ekene',
        ' Femi',
        ' Ffumbe',
        ' Fiskal',
        ' Folami',
        ' Gebhuza',
        ' Gero',
        ' Giconi',
        ' Hlatshwayo',
        ' Hlelile',
        ' Hlengiwe',
        ' Idowu',
        ' Imamu',
        ' Innocent',
        ' Kaffir',
        ' Kagiso',
        ' Kamau',
        ' Katlego',
        ' Lungile',
        ' Lusala',
        ' Mala',
        ' Manyara',
        ' Masego',
        ' Neo',
        ' Ngozi',
        ' Nkechi',
        ' Nkemdilim',
        ' Nkosana',
        ' Obi',
        ' Ochieng',
        ' Odhiambo',
        ' Paki',
        ' Per-Ui',
        ' Punnu',
        ' Qinisela',
        ' Quanda',
        ' Ramla',
        ' Refiloe',
        ' Reuben',
        ' Rudo',
        ' Tau',
        ' Temitope',
        ' Tendai',
        ' Thabo',
        ' Themba',
        ' Zola',
        ' Zuberi',
        ' Zuri',
        'Abdoulaye',
        'Abubakar',
        'Adama',
        'Ade',
        'Adebayo',
        'Adeleye',
        'Akwo',
        'Alain',
        'Aminou',
        'Andries',
        'Anthony',
        'Armand',
        'Arrey',
        'Asekevwe',
        'Ayodele',
        'Ayuk',
        'Babila',
        'Bakary',
        'Brice',
        'Celestin',
        'Cyrille',
        'Debo',
        'Dee',
        'Deo',
        'Dignity',
        'Divine',
        'Ebai',
        'Ebenezer',
        'Ebrahim',
        'Eghomie',
        'Elie',
        'Emershan',
        'Emmanuel',
        'Emmi',
        'Enow',
        'Etinosa',
        'Ezekiel',
        'Fabrice',
        'Fatih',
        'Felix',
        'Florent',
        'Gaetan',
        'Gideon',
        'Gilles',
        'Gires',
        'Godje',
        'Godwin',
        'Gregoire',
        'Guy',
        'Haco',
        'Herve',
        'Hifee',
        'Hugues',
        'Idris',
        'Ifeanyi',
        'Ikedimma',
        'Izor',
        'Jancu',
        'Kellan',
        'Kenia',
        'Kingsley',
        'Kopano',
        'Lachimi',
        'Landry',
        'Lawrence',
        'Lee',
        'Llewellyn',
        'Luma',
        'Lungelo',
        'Mamadou',
        'Manou',
        'Mathys',
        'Maxime',
        'Modibo',
        'Moletsane',
        'Monday',
        'Morne',
        'Mounir',
        'Moussa',
        'Mpho',
        'Muawiyah',
        'Mujahid',
        'Munyer',
        'Okosta',
        'Olamide',
        'Olaoye',
        'Olawale',
        'Olusola',
        'Opeyemi',
        'Ostenfosh',
        'Oumar',
        'Philemon',
        'Promise',
        'Raees',
        'Rodrigue',
        'Romuald',
        'Sadeeq',
        'Sadracko',
        'Salle',
        'Sampson',
        'Sekou',
        'Serge',
        'Severin',
        'Shafiou',
        'Sinmiloluwa',
        'Solomon',
        'Souleymane',
        'Spectra',
        'Tajudeen',
        'Tauriq',
        'Theodorex',
        'Tswelopele',
        'Tunji',
        'Uche',
        'Ugochukwu',
        'Ulrich',
        'Winslo',
        'Yanick',
        'Yaseen',
        'Youssouf',
        'Yvan',
        'Zaman',
        'Zubair'
    ],

    Western: [
        'Adrián',
        'Benjamín',
        'Carlos',
        'Rafael',
        'Pedro',
        'Roberto',
        'Pablo',
        'Luis',
        'Marcelo',
        'Jose',
        'Adam',
        'Aiden',
        'Brandon',
        'Christian',
        'Richard',
        'Evan',
        'Connor',
        'Ian',
        'Jack',
        'Julian',
        'Luke',
        'Michael',
        'Nathan',
        'Oliver',
        'Ryan',
        'Samuel',
        'Thomas',
        'Cameron',
        'Zachary',
        'Anton',
        'Florian',
        'Felix',
        'Jakob',
        'Karl',
        'Jonathan',
        'Mohammed',
        'Philipp',
        'Lukas',
        'Simon',
        'Daniel',
        'Dominik',
        'Daan',
        'Bram',
        'Sem',
        'Lucas',
        'Milan',
        'Levi',
        'Luuk',
        'Thijs',
        'Hugo',
        'Liam',
        'Axel',
        'Elias',
        'Viktor',
        'Ludvig',
        'Emil',
        'Audun',
        'Eirik',
        'Endre',
        'Frode',
        'Gunnar',
        'Helge',
        'Alessandro',
        'Antonio',
        'Carlo',
        'Dado',
        'Enrico',
        'Gianluigi',
        'Giuseppe',
        'Mario',
        'Julien',
        'Guillaume',
        'Romain',
        'Antoine',
        'Pierre',
        'Baptiste',
        'Jean',
        'Cédric',
        'Sylvain',
        'Franck',
        'Dima',
        'Nikita',
        'Alex',
        'Vlad',
        'Andrey',
        'Alexander',
        'Artem',
        'Dmitry',
        'Sergey',
        'Max',
        'Sasha',
        'Roman',
        'Kirill',
        'Pavel',
        'Igor',
        'Vika',
        'Vladislav',
        'Oxy',
        'Taras',
        'Bohdan'
    ],

    Asian: [
        'xin peng',
        'bo wen',
        'change miao',
        'cheng du',
        'de hui',
        'de mao',
        'du yuan',
        'gao jun',
        'hao xiao',
        'hao xuan',
        'he tai',
        'jia yu',
        'jia zhi',
        'jian bai',
        'jian gong',
        'jing qi',
        'jun lang',
        'jun wei',
        'kang yu',
        'lang zi',
        'li cheng',
        'ming cheng',
        'ming xu',
        'ming zhe',
        'peng xuan',
        'shao hui',
        'tian yu',
        'we qiang',
        'wei qi',
        'wei yu',
        'xi hua',
        'xi mao',
        'xi yuan',
        'xin xin',
        'xu yao',
        'ya chan',
        'yan bo',
        'yi he',
        'yi ning',
        'Rahul',
        'Aditya',
        'Abhishek',
        'Mahesh',
        'Shyam',
        'Yash',
        'Kumar',
        'Arjun',
        'Deepak',
        'Raj',
        'Amit',
        'Abhinav',
        'Rohit',
        'Ankur',
        'Parth',
        'Palash',
        'Rakesh',
        'Mayank',
        'Rishabh',
        'Sahil',
        'Dinesh',
        'Sunny',
        'Siddharth',
        'Nitin',
        'Akash',
        'Omkar',
        'Nishant',
        'Yuki',
        'Takuya',
        'Naoki',
        'Ken',
        'Takumi',
        'Kazuki',
        'Shota',
        'Kazu',
        'Hiro',
        'Keita',
        'Hiroki',
        'Taka',
        'Daiki',
        'Naoya',
        'Kang',
        'Jang',
        'Jung',
        'Shin',
        'Song',
        'Aditya',
        'Ahmad',
        'Farel',
        'Jonathan',
        'Anas',
        'Haliim',
        'Rizki',
        'Andre',
        'Kevin',
        'Rio',
        'Angga',
        'Denny',
        'Nattapong',
        'Tanawat',
        'Benz'
    ],

    African: [
        'Abebe',
        'Abimbola',
        'Adegoke',
        'Babajide',
        'Bapoto',
        'Chibueze',
        'Dabulamanzi',
        'Dambudzo',
        'Delmar',
        'Dube',
        'Femi',
        'Folami',
        'Giconi',
        'Hlengiwe',
        'Innocent',
        'Kamau',
        'Lusala',
        'Masego',
        'Nkechi',
        'Obi',
        'Paki',
        'Qinisela',
        'Refiloe',
        'Tau',
        'Thabo',
        'Zuberi',
        'Abubakar',
        'Adebayo',
        'Alain',
        'Anthony',
        'Asekevwe',
        'Bakary',
        'Cyrille',
        'Dignity',
        'Ebenezer',
        'Elie',
        'Emmi',
        'Ezekiel',
        'Felix',
        'Gideon',
        'Godje',
        'Guy',
        'Hugues',
        'Ifeanyi',
        'Izor',
        'Kenia',
        'Lachimi',
        'Llewellyn',
        'Mamadou',
        'Maxime',
        'Monday',
        'Moussa',
        'Mujahid',
        'Olamide',
        'Olusola',
        'Oumar',
        'Raees',
        'Sadeeq',
        'Sampson',
        'Severin',
        'Solomon',
        'Tajudeen',
        'Tswelopele',
        'Abbas',
        'Abdullah',
        'Adel',
        'Ahmed',
        'Alem',
        'Ali',
        'Amir',
        'Assem',
        'Bassel',
        'Dadi',
        'Djamal',
        'Enam',
        'Farouk',
        'Omar',
        'Firas',
        'Habib',
        'Hamza',
        'Hany',
        'Hesso',
        'Ibrahim',
        'Imed',
        'Karim',
        'Loay',
        'Lounis',
        'Magdi',
        'Mahmoud',
        'Malik',
        'Marawan',
        'Mehdi',
        'Mohammed',
        'Mohmed',
        'Mostafa',
        'Mustafa',
        'Nadir',
        'Nasr',
        'Nassim',
        'Nazir'
    ]
};
