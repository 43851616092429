export default [
    ['skateboarding', 'skateboarding'],
    ['poker', 'poker'],
    ['skydiving', 'fallskjermhopping'],
    ['gaming', 'dataspill'],
    ['baseball', 'baseball'],
    ['basketball', 'basketball'],
    ['cricket', 'cricket'],
    ['football', 'football'],
    ['golf', 'golf'],
    ['ping pong', 'bordtennis'],
    ['tennis', 'tennis'],
    ['rugby', 'rugby'],
    ['boxing', 'boksing'],
    ['martial arts', 'kampsport'],
    ['parasailing', 'paragliding'],
    ['climbing', 'klatring'],
    ['fencing', 'fekting'],
    ['drawing', 'tegning'],
    ['camping', 'camping'],
    ['fishing', 'fisking'],
    ['hiking', 'turgåing'],
    ['travelling', 'reising'],
    ['cooking', 'matlaging'],
    ['singing', 'synging'],
    ['dancing', 'dans'],
    ['theatre', 'teater'],
    ['opera', 'opera'],
    ['ballet', 'ballett'],
    ['cycling', 'sykling'],
    ['mountain biking', 'mountain biking'],
    ['musikk', 'musikk'],
    ['guitar', 'gitar'],
    ['drums', 'trommer'],
    ['piano', 'piano'],
    ['shopping', 'shopping'],
    ['fashion', 'mote'],
    ['blogging', 'blogging'],
    ['jogging', 'jogging'],
    ['motor sports', 'motorsport'],
    ['gymnastics', 'turn'],
    ['horseriding', 'ridning'],
    ['chess', 'sjakk'],
    ['reading books', 'lese bøker'],
    ['wrestling', 'bryting'],
    ['sudoku', 'sudoku'],
    ['watch movies', 'se filmer'],
    ['yoga', 'yoga'],
    ['meditation', 'meditasjon'],
    ['handball', 'håndball'],
    ['hunting', 'jakt'],
    ['kayaking', 'kayakk'],
    ['kiteboarding', 'kiteboarding'],
    ['rollerblading', 'rollerblades'],
    ['fitness gym', 'helsestudio'],
    ['skiing', 'ski'],
    ['snowboarding', 'snowboard'],
    ['bowling', 'bowling'],
    ['billiards', 'biljard'],
    ['breakdancing', 'breakdance'],
    ['volleyball', 'volleyball'],
    ['go-carting', 'gokart'],
    ['squash', 'squash'],
    ['surfing', 'surfing'],
    ['windsurfing', 'windsurfing'],
    ['triathlon', 'triathlon'],
    ['astronomy', 'astronomi'],
    ['astrology', 'astrologi'],
    ['brain training', 'hjernetrening'],
    ['paintball', 'paintball'],
    ['brain-body training', 'brain-body trening']
];
