<template>
  <div class="FaceCardForTest">
    <div
      class="image"
      :style="{ backgroundImage: `url(${sourceItem.data.image})` }" />
    <div class="infoSection">
      <div
        v-for="(test, i) of tests"
        :key="i"
        class="Test"
        :class="{ active: test.active, done: test.done }">
        <span
          v-if="test.done"
          class="Test__indicator Test__doneIndicator">
          <svgicon
            class="svgIcon"
            icon="checkmark" />
        </span>
        <span
          v-else-if="test.active"
          class="Test__indicator Test__activeIndicator">
          <svgicon
            class="svgIcon"
            icon="chevron-down" />
        </span>
        <span
          v-else
          class="Test__indicator Test__emptyIndicator" />

        <span
          v-if="test.done"
          class="Test__label">{{ test.value }}</span>
        <span
          v-else
          class="Test__label">{{ test.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import last from 'lodash-es/last';

export default {
    inject: ['module'],
    props: {
        questionIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        session() {
            return this.module.state;
        },
        quizItem() {
            return this.session.quiz.items[this.questionIndex];
        },
        sourceItem() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex];
        },
        tests() {
            const labelMap = {
                nameTest: this.$t('currentGame.FaceCardForTest.firstname'),
                surnameTest: this.$t('currentGame.FaceCardForTest.surname'),
                professionTest: this.$t('currentGame.FaceCardForTest.profession'),
                hobbyTest: this.$t('currentGame.FaceCardForTest.hobby')
            };
            const currentQuizItemTest = last(this.quizItem.tests);

            let activeTestIndex = -1;
            let lastDoneTestIndex = -1;
            if (!(currentQuizItemTest.done && currentQuizItemTest.score === 1)) {
                activeTestIndex = this.quizItem.testNames.indexOf(currentQuizItemTest.name);
                lastDoneTestIndex = activeTestIndex - 1;
            } else {
                lastDoneTestIndex = this.quizItem.testNames.indexOf(currentQuizItemTest.name);
            }

            return this.quizItem.testNames.map((testName, i) => {
                const attrName = testName.substr(0, testName.length - 'Test'.length);

                return {
                    label: labelMap[testName],
                    value: this.sourceItem.data[attrName],
                    done: i <= lastDoneTestIndex,
                    active: currentQuizItemTest.name === testName
                };
            });
        }
    }
};
</script>

<style scoped lang="scss">
.FaceCardForTest {
    border-radius: 0.5em;
    overflow: hidden;
    display: flex;

    color: white;
}
.image {
    width: 9em;
    height: 9em;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.infoSection {
    background-color: rgba(black, 0.3);
    border-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1em;

    flex: 1;
}

.Test {
    margin: 0.2em 0;
    display: flex;
    align-items: center;

    &.done {
        .Test__label {
            color: rgba(white, 0.8);
        }
    }
}
.Test__indicator {
    $size: 1em;
    width: $size;
    height: $size;
    margin-right: 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;
}
.Test__activeIndicator {
    border-radius: 50%;
    .svgIcon {
        $size: 80%;
        width: $size;
        height: $size;
        fill: white;
        transform: rotate(-90deg);
        margin-left: 10%;
    }
}
.svgIcon {
    $size: 50%;
    width: $size;
    height: $size;
    fill: black;
}
.Test__doneIndicator {
    border-radius: 50%;
    background-color: $memogenius-greenColor;
}
.Test__label {
    font-size: 110%;
    color: rgba(white, 0.4);
}
</style>
