<template>
  <div class="MemorizeItem">
    <div class="spacerTop" />
    <div class="card">
      <div
        class="image"
        :style="{ backgroundImage: `url(${image})` }" />
      <div class="infoSection">
        <div class="name">
          {{ name }} {{ surname }}
        </div>
        <div
          v-if="profession"
          class="profession">
          {{ profession }}
        </div>
        <div
          v-if="hobby"
          class="hobby">
          Hobby - {{ hobby }}
        </div>
      </div>
    </div>
    <div class="spacerBottom" />
  </div>
</template>

<script>
export default {
    props: ['image', 'name', 'surname', 'profession', 'hobby']
};
</script>

<style scoped lang="scss">
.MemorizeItem {
    // background-color: white;
    // background-color: rgba(black, 0.1);//#31277C; //rgba(black, 0.1);

    // box-shadow: 0 0 1em rgba(black, 0.3);

    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacerTop {
    flex: 0.3;
}
.spacerBottom {
    flex: 1;
}
.card {
    border-radius: 0.5em;
    overflow: hidden;
    color: white;
    // width: 20em;
    // height: 20em;
    // border: 1px solid white;
}
.image {
    width: 16em;
    height: 16em;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.infoSection {
    background-color: rgba(black, 0.3); //#31277C; //rgba(black, 0.1);
    padding: 1em;
    // border: 1px solid rgba(white, 0.1);
    border-radius: 0 0 0.5em 0.5em;
    border-top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.name {
    // padding: 1em;
    text-align: center;
    font-size: 130%;
    // color: pink;
}
.profession {
    padding: 0.2em 1em;
    background-color: #f2c500;
    border-radius: 2em;
    color: black;
    margin-top: 0.5em;
    // border-top: 1px solid rgba(black, 0.05);
}
.hobby {
    // padding: 1em;
    color: rgba(white, 0.7);
    margin-top: 1.5em;
    // border-top: 1px solid rgba(black, 0.05);
}
</style>
