import store from '@/store';

function generateLevel(
    { numberOfItems, includeSurname = false, includeProfession = false, includeHobby = false },
    countryOptions
) {
    const complexity = [true, includeSurname, includeProfession, includeHobby].filter(x => x).length;
    const itemTime = 12 * complexity;

    return {
        numberOfItems,
        includeSurname,
        includeProfession,
        includeHobby,
        itemMemorizeTimeLimit: itemTime,
        itemQuizTimeLimit: itemTime,
        stars2: itemTime * 0.75,
        stars3: itemTime * 0.5,
        countryOptions
    };
}

function getCountryOptions(homeCountry, levelIndex) {
    const countryOptions = [];
    if (levelIndex < 5) {
        // getFace(null, homeCountry, totalItems);
        countryOptions.push({ country: homeCountry, fraction: 1 });
    } else if (levelIndex < 8) {
        countryOptions.push({ country: homeCountry, fraction: 1 / 2 });
        countryOptions.push({ country: 'Western', fraction: 1 / 2 });
    } else {
        countryOptions.push({ country: homeCountry, fraction: 1 / 3 });
        countryOptions.push({ country: 'Western', fraction: 1 / 3 });
        countryOptions.push({ country: 'Asian', fraction: 1 / 6 });
        countryOptions.push({ country: 'African', fraction: 1 / 6 });
    }
    return countryOptions;
}

const rawLevelOptions = [
    { numberOfItems: 4 },
    { numberOfItems: 6 },

    { numberOfItems: 8, includeSurname: true },
    { numberOfItems: 12, includeSurname: true },
    { numberOfItems: 16, includeSurname: true },

    { numberOfItems: 20, includeSurname: true, includeProfession: true },
    { numberOfItems: 24, includeSurname: true, includeProfession: true },
    { numberOfItems: 28, includeSurname: true, includeProfession: true },

    { numberOfItems: 28, includeSurname: true, includeProfession: true, includeHobby: true },
    { numberOfItems: 32, includeSurname: true, includeProfession: true, includeHobby: true },
    { numberOfItems: 36, includeSurname: true, includeProfession: true, includeHobby: true },
    { numberOfItems: 40, includeSurname: true, includeProfession: true, includeHobby: true }
];

export default function getLevelOptions(levelIndex) {
    const language = store.getters['moduleApp/locale'];
    const homeCountry = language === 'no' ? 'Norway' : 'USA';
    return generateLevel(rawLevelOptions[levelIndex], getCountryOptions(homeCountry, levelIndex));
}
