export default [
    ['architect', 'arkitekt'],
    ['bartender', 'bartender'],
    ['farmer', 'bonde'],
    ['florist', 'florist'],
    ['lawyer', 'advokat'],
    ['business owner', 'bedriftseier'],
    ['doctor', 'doktor'],
    ['director', 'direktør'],
    ['waitress', 'servitør'],
    ['real estate broker', 'eiendomsmegler'],
    ['gynecologist', 'gynekolog'],
    ['housewife', 'husmor'],
    ['green grocer', 'grønnsakshandler'],
    ['engineer', 'ingeniør'],
    ['chemist', 'kjemiker'],
    ['chef', 'kokk'],
    ['editor', 'redaktør'],
    ['nurse', 'sykepleier'],
    ['interpreter', 'tolk'],
    ['receptionist', 'resepsjonist'],
    ['carpenter', 'snekker'],
    ['soldier', 'soldat'],
    ['scientist', 'vitenskapsmann'],
    ['secretary', 'sekretær'],
    ['dentist', 'tannlege'],
    ['author', 'forfatter'],
    ['office worker', 'kontorarbeider'],
    ['painter', 'maler'],
    ['photographer', 'fotograf'],
    ['musician', 'musiker'],
    ['chairman', 'leder'],
    ['pharmacist', 'apotek'],
    ['counsellor', 'rådgiver'],
    ['veterinarian', 'veterinær'],
    ['translator', 'oversetter'],
    ['computer programmer', 'dataprogrammerer'],
    ['assistant', 'assistent'],
    ['mechanical engineer', 'maskiningeniør'],
    ['general manager', 'daglig leder'],
    ['goldsmith', 'gullsmed'],
    ['blacksmith', 'smed'],
    ['accountant', 'regnskapsfører'],
    ['teacher', 'lærer'],
    ['maintenance worker', 'vedlikeholdsarbeider'],
    ['insurance agent', 'forsikringsagent'],
    ['paramedic', 'ambulansearbeider'],
    ['hairdresser', 'frisør'],
    ['fashion stylist', 'motestylist'],
    ['physiotherapist', 'fysioterapeut'],
    ['event planner', 'eventorganisator'],
    ['sales representative', 'salgsrepresentant'],
    ['butcher', 'slakter'],
    ['hunter', 'jeger'],
    ['plumber', 'rørlegger'],
    ['glazier', 'glassmester'],
    ['art director', 'art director'],
    ['auto mechanic', 'bilmekaniker'],
    ['bus driver', 'bussjåfør'],
    ['cook', 'kokk'],
    ['sports coach', 'sportstrener'],
    ['mason', 'murer'],
    ['carpenter', 'snekker'],
    ['obstetrician', 'jordmor'],
    ['cashier', 'kasserer'],
    ['antique dealer', 'antikkforhandler'],
    ['ventriloquist', 'buktaler'],
    ['electrician', 'elektriker'],
    ['housekeeper', 'husholderske'],
    ['security guard', 'sikkerhetsvakt'],
    ['inventor', 'oppfinner'],
    ['shop assistant', 'butikkassistent'],
    ['masseur', 'massør'],
    ['host', 'vert'],
    ['radio speaker', 'radiovert'],
    ['designer', 'designer'],
    ['businessman', 'forretningsmann'],
    ['a consultant', 'konsulent'],
    ['a psychologist', 'psykolog'],
    ['a psychiatrist', 'psykiater'],
    ['CEO', 'CEO'],
    ['CTO', 'CTO'],
    ['CFO', 'CFO'],
    ['student', 'student']
];
