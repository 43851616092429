<template>
  <div class="Question">
    <div
      class="question"
      :style="{ color: theme.primaryColor }">
      {{
        $t(`currentGame.Question.${currentTestName}`, {
          pronoun: $t(`currentGame.Question.${question.gender}`)
        })
      }}
    </div>

    <FaceCardForTest :question-index="itemIndex" />
  </div>
</template>

<script>
import last from 'lodash-es/last';
import FaceCardForTest from './FaceCardForTest';

export default {
    components: { FaceCardForTest },
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'theme'],
    data() {
        return {
            delayedDoneMap: {
                // nameTest: true,
                // surnameTest: true,
                // professionTest: true,
                // hobbyTest: true
            }
        };
    },
    computed: {
        doneMap() {
            const map = {};
            this.quizItem.tests.forEach(t => {
                map[t.name] = t.score;
            });
            return map;
        },
        session() {
            return this.module.state;
        },
        quizItem() {
            return this.module.getters('quiz/getItem')(this.itemIndex);
        },
        currentTestName() {
            return last(this.quizItem.tests).name;
        },
        question() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex].data;
        }
    },
    watch: {
        doneMap(newMap) {
            setTimeout(() => {
                this.delayedDoneMap = newMap;
            }, 1000);
            setTimeout(() => {
                this.$emit('feedback-completed');
            }, 2000);
        }
    }
};
</script>

<style scoped lang="scss">
.Question {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 2em;
}

.question {
    font-size: 130%;
    text-align: center;
}

.FaceCardForTest {
    margin: 2em 0;
    width: 100%;
}
</style>
