export default {
    USA: [
        'Barnes',
        'Miller',
        'Bell',
        'Reed',
        'Brown',
        'Baker',
        'Díaz',
        'Campbell',
        'Myers',
        'Carter',
        'Moore',
        'Cox',
        'Cruz',
        'King',
        'Reyes',
        'Hall',
        'Bailey',
        'Wood',
        'Fisher',
        'Flores',
        'Parker',
        'Davis',
        'Green',
        'Smith',
        'Adams',
        'Thomas',
        'Cooper',
        'Scott',
        'Roberts',
        'Price',
        'Rivera',
        'Long',
        'Sanders',
        'Butler',
        'Walker',
        'Turner',
        'Nguyen',
        'Cook',
        'Hughes',
        'Gray',
        'Collins',
        'Anderson',
        'Harris',
        'Brooks',
        'Watson',
        'Young',
        'Bennett',
        'Richardson',
        'Russell',
        'Phillips',
        'Martínez',
        'Martin',
        'James',
        'Morris',
        'Jones',
        'Evans',
        'Lee',
        'Wright',
        'Robinson',
        'White',
        'Allen',
        'Ward',
        'Lewis',
        'Taylor',
        'Edwards',
        'Jackson',
        'Hill',
        'Stewart',
        'Clark',
        'Williams',
        'Powell',
        'Jenkins',
        'Foster',
        'García',
        'Gómez',
        'González',
        'Gutiérrez',
        'Hernández',
        'Howard',
        'Johnson',
        'Kelly',
        'López',
        'Mitchell',
        'Morales',
        'Morgan',
        'Murphy',
        'Nelson',
        'Ortiz',
        'Pérez',
        'Perry',
        'Peterson',
        'Ramírez',
        'Rodríguez',
        'Rogers',
        'Ross',
        'Sánchez',
        'Sullivan',
        'Thompson',
        'Torres',
        'Wilson'
    ],

    Spain: [
        'Díaz',
        'Morán',
        'Rodríguez',
        'González',
        'Hernández',
        'Morales',
        'Sánchez',
        'Ramírez',
        'Pérez',
        'Calderón',
        'Gutiérrez',
        'Rojas',
        'Salas',
        'Vargas',
        'Torres',
        'Segura',
        'Valverde',
        'Villalobos',
        'Araya',
        'Herrera',
        'López',
        'Madrigal',
        'Hernández',
        'Martínez',
        'García',
        'Rodríguez',
        'Flores',
        'Pérez',
        'Rivera',
        'Vásquez',
        'Cruz',
        'Mejía',
        'Rivas',
        'Reyes',
        'Ramos',
        'Gómez',
        'Portillo',
        'Escobar',
        'Orellana',
        'Romero',
        'Aguilar',
        'Alvarado',
        'Fernández',
        'Rodríguez',
        'López',
        'Martín',
        'Gómez',
        'Ruiz',
        'Jiménez',
        'Díaz',
        'Álvarez',
        'Moreno',
        'Muñoz',
        'Alonso',
        'Navarro',
        'Domínguez',
        'Gil',
        'Vázquez',
        'Serrano',
        'Blanco',
        'Sanz',
        'Castro',
        'Suárez',
        'Ortega',
        'Rubio',
        'Molina',
        'Delgado',
        'Ortiz',
        'Marín',
        'Iglesias',
        'Acuña',
        'Benitez',
        'Bianchi',
        'Blanco',
        'Bruno',
        'Cabrera',
        'Campos',
        'Carrizo',
        'Castillo',
        'Castro',
        'Cohen',
        'Colombo',
        'Correa',
        'Costa',
        'Ferrari',
        'Ferreyra',
        'Figueroa',
        'Garcia',
        'Silva',
        'Soria',
        'Sosa',
        'Soto',
        'Suarez',
        'Vega',
        'Vera',
        'Vidal',
        'Villalba'
    ],

    Portugal: [
        'Abreu',
        'Achilles',
        'Albuquerque',
        'Almeida',
        'Alves',
        'Antunes',
        'Araújo',
        'Araújo',
        'Araullo',
        'Azevedo',
        'Barbosa',
        'Barros',
        'Basurto',
        'Belo',
        'Cabral',
        'Campos',
        'Cardoso',
        'Cardozo',
        'Carvalho',
        'Castro',
        'Coelho',
        'Correia',
        'Costa',
        'Crespo',
        'Cruz',
        'Cunha',
        "D'Cruz",
        "D'Cruze",
        'Delgado',
        'Duarte',
        'Estéves',
        'Fernandes',
        'Ferreira',
        'Ferro',
        'Figueiredo',
        'Fonseca',
        'Franco',
        'Freitas',
        'Garcia',
        'Gaspar',
        'Gomes',
        'Gonçalves',
        'Gouveia',
        'Guerra',
        'Henriques',
        'Jesus',
        'Lobo',
        'Lopes',
        'Lourenço',
        'Machado',
        'Magalhães',
        'Maria',
        'Marques',
        'Martins',
        'Mata',
        'Mateus',
        'Matos',
        'Medeiros',
        'Melo',
        'Mendes',
        'Monteiro',
        'Moreira',
        'Moreno',
        'Neves',
        'Nunes',
        'Oliveira',
        'Palmeiro',
        'Paredes',
        'Pereira',
        'Pinheiro',
        'Pinho',
        'Pinto',
        'Pires',
        'Ramos',
        'Reis',
        'Ribeiro',
        'Rios',
        'Rocha',
        'Rodrigues',
        'Romão',
        'Rosario',
        'Salazar',
        'Santana',
        'Santiago',
        'Santos',
        'Serafim',
        'Silva',
        'Silveira',
        'Simões',
        'Soares',
        'Sousa',
        'Souza',
        'Tavares',
        'Teixeira',
        'Torres',
        'Vargas',
        'Ventura',
        'Victor'
    ],

    Germany: [
        'Müller',
        'Schmidt',
        'Schneider',
        'Fischer',
        'Weber',
        'Schäfer',
        'Meyer',
        'Wagner',
        'Becker',
        'Bauer',
        'Hoffmann',
        'Schulz',
        'Koch',
        'Richter',
        'Klein',
        'Wolf',
        'Schröder',
        'Neumann',
        'Braun',
        'Werner',
        'Schwarz',
        'Hofmann',
        'Zimmermann',
        'Schmitt',
        'Hartmann',
        'Schmid',
        'Weiß',
        'Schmitz',
        'Krüger',
        'Lange',
        'Meier',
        'Walter',
        'Köhler',
        'Maier',
        'Beck',
        'König',
        'Krause',
        'Schulze',
        'Huber',
        'Mayer',
        'Frank',
        'Lehmann',
        'Kaiser',
        'Fuchs',
        'Herrmann',
        'Lang',
        'Thomas',
        'Peters',
        'Stein',
        'Jung',
        'Möller',
        'Berger',
        'Martin',
        'Friedrich',
        'Scholz',
        'Keller',
        'Groß',
        'Hahn',
        'Roth',
        'Günther',
        'Vogel',
        'Schubert',
        'Winkler',
        'Schuster',
        'Jäger',
        'Lorenz',
        'Ludwig',
        'Baumann',
        'Heinrich',
        'Otto',
        'Simon',
        'Graf',
        'Kraus',
        'Krämer',
        'Böhm',
        'Schulte',
        'Albrecht',
        'Franke',
        'Winter',
        'Schumacher',
        'Vogt',
        'Haas',
        'Sommer',
        'Schreiber',
        'Engel',
        'Ziegler',
        'Dietrich',
        'Brandt',
        'Seidel',
        'Kuhn',
        'Busch',
        'Horn',
        'Arnold',
        'Kühn',
        'Bergmann',
        'Pohl',
        'Pfeiffer',
        'Wolff',
        'Voigt',
        'Sauer'
    ],

    Netherlands: [
        'De Jong',
        'Jansen',
        'De Vries',
        'Van Den Berg',
        'Van Dijk',
        'Bakker',
        'Janssen',
        'Visser',
        'Smit',
        'Meijer',
        'De Boer',
        'Mulder',
        'De Groot',
        'Bos',
        'Vos',
        'Peters',
        'Hendriks',
        'Van Leeuwen',
        'Dekker',
        'Brouwer',
        'De Wit',
        'Dijkstra',
        'Smits',
        'De Graaf',
        'Van Der Meer'
    ],

    Sweden: [
        'Johansson',
        'Andersson',
        'Karlsson',
        'Nilsson',
        'Eriksson',
        'Larsson',
        'Olsson',
        'Persson',
        'Svensson',
        'Gustafsson',
        'Pettersson',
        'Jonsson',
        'Jansson',
        'Hansson',
        'Bengtsson',
        'Jönsson',
        'Petersson',
        'Carlsson',
        'Magnusson',
        'Lindberg',
        'Gustavsson',
        'Olofsson',
        'Lindström',
        'Lindgren',
        'Axelsson',
        'Jakobsson',
        'Lundberg',
        'Bergström',
        'Lundgren',
        'Berg',
        'Berglund',
        'Fredriksson',
        'Mattsson',
        'Sandberg',
        'Henriksson',
        'Sjöberg',
        'Forsberg',
        'Håkansson',
        'Lindqvist',
        'Danielsson',
        'Engström',
        'Lind',
        'Lundin',
        'Eklund',
        'Gunnarsson',
        'Fransson',
        'Samuelsson',
        'Johnsson',
        'Holm',
        'Bergman',
        'Holmberg',
        'Nyström',
        'Lundqvist',
        'Arvidsson',
        'Isaksson',
        'Mårtensson',
        'Björk',
        'Nyberg',
        'Söderberg',
        'Nordström',
        'Lundström',
        'Wallin',
        'Eliasson',
        'Björklund',
        'Berggren',
        'Hermansson',
        'Ström',
        'Sandström',
        'Nordin',
        'Åberg',
        'Holmgren',
        'Ekström',
        'Sundberg',
        'Hedlund',
        'Sjögren',
        'Månsson',
        'Martinsson',
        'Dahlberg',
        'Öberg',
        'Abrahamsson',
        'Jonasson',
        'Strömberg',
        'Andreasson',
        'Hellström',
        'Åkesson',
        'Blom',
        'Norberg',
        'Blomqvist',
        'Sundström',
        'Göransson',
        'Åström',
        'Ek',
        'Söderström',
        'Ivarsson',
        'Löfgren',
        'Lindholm',
        'Nyman',
        'Bergqvist',
        'Lund',
        'Falk'
    ],

    Denmark: [
        'Jensen',
        'Nielsen',
        'Hansen',
        'Pedersen',
        'Andersen',
        'Christensen',
        'Larsen',
        'Sørensen',
        'Rasmussen',
        'Jørgensen',
        'Petersen',
        'Madsen',
        'Kristensen',
        'Olsen',
        'Thomsen',
        'Christiansen',
        'Poulsen',
        'Johansen',
        'Møller',
        'Mortensen',
        'Knudsen',
        'Jakobsen',
        'Jacobsen',
        'Mikkelsen',
        'Olsen',
        'Frederiksen',
        'Laursen',
        'Henriksen',
        'Lund',
        'Schmidt',
        'Holm',
        'Eriksen',
        'Kristiansen',
        'Simonsen',
        'Clausen',
        'Svendsen',
        'Andreasen',
        'Iversen',
        'Østergaard',
        'Jeppesen',
        'Vestergaard',
        'Nissen',
        'Lauridsen',
        'Kjær',
        'Jespersen',
        'Mogensen',
        'Nørgaard',
        'Jepsen',
        'Frandsen',
        'Jessen',
        'Carlsen',
        'Dahl',
        'Skov',
        'Søndergaard',
        'Christoffersen',
        'Bertelsen',
        'Bruun',
        'Lassen',
        'Gregersen',
        'Bach',
        'Friis',
        'Kjeldsen',
        'Johnsen',
        'Steffensen',
        'Lauritsen',
        'Krogh',
        'Bech',
        'Danielsen',
        'Andresen',
        'Mathiesen',
        'Toft',
        'Winther',
        'Ravn',
        'Brandt',
        'Dam',
        'Holst',
        'Lind',
        'Mathiasen',
        'Berg',
        'Overgaard',
        'Bak',
        'Nilsson',
        'Schultz',
        'Klausen',
        'Kristoffersen',
        'Paulsen',
        'Schou',
        'Koch',
        'Thorsen',
        'Hermansen',
        'Thygesen',
        'Nygaard',
        'Bang',
        'Kruse',
        'Karlsen',
        'Juhl',
        'Villadsen',
        'Hedegaard',
        'Lorenzen',
        'Damgaard'
    ],

    UK: [
        'Smith',
        'Jones',
        'Williams',
        'Taylor',
        'Brown',
        'Davies',
        'Evans',
        'Wilson',
        'Thomas',
        'Johnson',
        'Roberts',
        'Robinson',
        'Thompson',
        'Wright',
        'Walker',
        'White',
        'Edwards',
        'Hughes',
        'Green',
        'Hall',
        'Lewis',
        'Harris',
        'Clarke',
        'Patel',
        'Jackson',
        'Wood',
        'Turner',
        'Martin',
        'Cooper',
        'Hill',
        'Ward',
        'Morris',
        'Moore',
        'Clark',
        'Lee',
        'King',
        'Baker',
        'Harrison',
        'Morgan',
        'Allen',
        'James',
        'Scott',
        'Phillips',
        'Watson',
        'Davis',
        'Parker',
        'Price',
        'Bennett',
        'Young',
        'Griffiths',
        'Mitchell',
        'Kelly',
        'Cook',
        'Carter',
        'Richardson',
        'Bailey',
        'Collins',
        'Bell',
        'Shaw',
        'Murphy',
        'Miller',
        'Cox',
        'Richards',
        'Khan',
        'Marshall',
        'Anderson',
        'Simpson',
        'Ellis',
        'Adams',
        'Singh',
        'Begum',
        'Wilkinson',
        'Foster',
        'Chapman',
        'Powell',
        'Webb',
        'Rogers',
        'Gray',
        'Mason',
        'Ali',
        'Hunt',
        'Hussain',
        'Campbell',
        'Matthews',
        'Owen',
        'Palmer',
        'Holmes',
        'Mills',
        'Barnes',
        'Knight',
        'Lloyd',
        'Butler',
        'Russell',
        'Barker',
        'Fisher',
        'Stevens',
        'Jenkins',
        'Murray',
        'Dixon',
        'Harvey'
    ],

    Italy: [
        'Amato',
        'Barbieri',
        'Barone',
        'Basile',
        'Battaglia',
        'Bellini',
        'Benedetti',
        'Bernardi',
        'Bianchi',
        'Bianco',
        'Bruno',
        'Caputo',
        'Carbone',
        'Caruso',
        'Cattaneo',
        'Colombo',
        'Conte',
        'Conti',
        'Coppola',
        'Costa',
        'Costantini',
        "D'Amico",
        "D'Angelo",
        'De Angelis',
        'De Luca',
        'De Rosa',
        'De Santis',
        'Donati',
        'Esposito',
        'Esposito',
        'Fabbri',
        'Farina',
        'Ferrara',
        'Ferrari',
        'Ferraro',
        'Ferretti',
        'Ferri',
        'Fiore',
        'Fontana',
        'Galli',
        'Gallo',
        'Gatti',
        'Gentile',
        'Giordano',
        'Giuliani',
        'Grassi',
        'Greco',
        'Guerra',
        'Leone',
        'Lombardi',
        'Lombardo',
        'Longo',
        'Mancini',
        'Mantovani',
        'Marchetti',
        'Mariani',
        'Marini',
        'Marino',
        'Martinelli',
        'Martini',
        'Martino',
        'Mazza',
        'Messina',
        'Milani',
        'Montanari',
        'Monti',
        'Morelli',
        'Moretti',
        'Negri',
        'Neri',
        'Orlando',
        'Pagano',
        'Palmieri',
        'Palumbo',
        'Parisi',
        'Pellegrini',
        'Pellegrino',
        'Piras',
        'Ravelli',
        'Ricci',
        'Rinaldi',
        'Riva',
        'Rizzi',
        'Rizzo',
        'Romano',
        'Romano',
        'Rossetti',
        'Rossi',
        'Ruggiero',
        'Russo',
        'Sala',
        'Sanna',
        'Sante',
        'Santoro',
        'Sartori',
        'Serra',
        'Silvestri',
        'Sorrentino',
        'Testa',
        'Valentini',
        'Villa',
        'Vitale',
        'Vitali'
    ],

    France: [
        'Martin',
        'Bernard',
        'Dubois',
        'Thomas',
        'Robert',
        'Richard',
        'Petit',
        'Durand',
        'Leroy',
        'Moreau',
        'Simon',
        'Laurent',
        'Lefebvre',
        'Michel',
        'Garcia',
        'David',
        'Bertrand',
        'Roux',
        'Vincent',
        'Fournier',
        'Morel',
        'Girard',
        'Andre',
        'Lefevre',
        'Mercier',
        'Dupont',
        'Lambert',
        'Bonnet',
        'Francois',
        'Martinez',
        'Legrand',
        'Garnier',
        'Faure',
        'Rousseau',
        'Blanc',
        'Guerin',
        'Muller',
        'Henry',
        'Roussel',
        'Nicolas',
        'Perrin',
        'Morin',
        'Mathieu',
        'Clement',
        'Gauthier',
        'Dumont',
        'Lopez',
        'Fontaine',
        'Chevalier',
        'Robin',
        'Masson',
        'Sanchez',
        'Gerard',
        'Nguyen',
        'Boyer',
        'Denis',
        'Lemaire',
        'Duval',
        'Joly',
        'Gautier',
        'Roger',
        'Roche',
        'Roy',
        'Noel',
        'Meyer',
        'Lucas',
        'Meunier',
        'Jean',
        'Perez',
        'Marchand',
        'Dufour',
        'Blanchard',
        'Marie',
        'Barbier',
        'Brun',
        'Dumas',
        'Brunet',
        'Schmitt',
        'Leroux',
        'Colin',
        'Fernandez',
        'Pierre',
        'Renard',
        'Arnaud',
        'Rolland',
        'Caron',
        'Aubert',
        'Giraud',
        'Leclerc',
        'Vidal',
        'Bourgeois',
        'Renaud',
        'Lemoine',
        'Picard',
        'Gaillard',
        'Philippe',
        'Leclercq',
        'Lacroix',
        'Fabre',
        'Dupuis'
    ],

    Russia: [
        'Abramtsov',
        'Akimov',
        'Aksenov',
        'Aleksandrov',
        'Anisimov',
        'Arkhipov',
        'Baranov',
        'Belousov',
        'Belov',
        'Belyaev',
        'Belyakov',
        'Biryukov',
        'Bogdanov',
        'Bogdanov',
        'Boricov',
        'Bykov',
        'Chernov',
        'Chernyshev',
        'Chistyakov',
        'Danilov',
        'Demidov',
        'Denisov',
        'Dmitriev',
        'Efimov',
        'Egorov',
        'Ermakov',
        'Evdokimov',
        'Fedorov',
        'Filatov',
        'Filimonov',
        'Filippov',
        'Fomin',
        'Frolov',
        'Gerasimov',
        'Golubev',
        'Grigorev',
        'Gromov',
        'Ignatev',
        'Ilin',
        'Ivanov',
        'Karpov',
        'Khokhlov',
        'Kiselev',
        'Klimov',
        'Kolesnikov',
        'Kovalenko',
        'Kovalev',
        'Kozlov',
        'Kuzmin',
        'Kuznetsov',
        'Kyznetsov',
        'Larionov',
        'Lebedev',
        'Lebedev',
        'Leonov',
        'Lobanov',
        'Loginov',
        'Lukanov',
        'Lukin',
        'Melnikov',
        'Mikhailov',
        'Mironov',
        'Morozov',
        'Morozov',
        'Nazarov',
        'Nesterov',
        'Nikitin',
        'Nikolaev',
        'Novikov',
        'Novikov',
        'Osipov',
        'Pavlov',
        'Petrenko',
        'Petrov',
        'Polyakov',
        'Ponomarev',
        'Popov',
        'Prokhorov',
        'Savelev',
        'Savin',
        'Semenov',
        'Semyonov',
        'Shcherbakov',
        'Smirnov',
        'Sokolov',
        'Solovyov',
        'Sorokin',
        'Spiridonov',
        'Stepanov',
        'Suvorov',
        'Tarasov',
        'Tikhomirov',
        'Tikhonov',
        'Timofeev',
        'Titov',
        'Ushakov',
        'Vasilyev',
        'Vinogradov',
        'Vlasov',
        'Volkov',
        'Vorobev',
        'Vorobyov',
        'Voronin',
        'Voronov',
        'Yakovlev',
        'Yudin',
        'Zaitsev',
        'Zakharov',
        'Zaytsev'
    ],

    Ukraine: [
        'Melnyk',
        'Shevchenko',
        'Boyko',
        'Kovalenko',
        'Bondarenko',
        'Tkachenko',
        'Kovalchuk',
        'Kravchenko',
        'Oliynyk (Oliinyk)',
        'Shevchuk',
        'Koval',
        'Polishchuk',
        'Bondar',
        'Tkachuk',
        'Moroz',
        'Marchenko',
        'Lysenko',
        'Rudenko',
        'Savchenko',
        'Petrenko',
        'Kravchuk',
        'Klymenko',
        'Pavlenko',
        'Savchuk',
        'Kuzmenko',
        'Shvets',
        'Havryliuk (Havrylyuk)',
        'Ivanova',
        'Kharchenko',
        'Ponomarenko',
        'Melnychuk',
        'Vasylenko',
        'Mazur',
        'Khomenko',
        'Levchenko',
        'Karpenko',
        'Kushnir',
        'Sydorenko',
        'Romaniuk (Romanyuk)',
        'Martyniuk (Martynyuk)',
        'Kolomiyets',
        'Pavliuk (Pavlyuk)',
        'Tkach',
        'Lytvynenko',
        'Panchenko',
        'Kostenko',
        'Kulyk',
        'Kravets',
        'Yurchenko',
        'Bilous',
        'Dyachenko',
        'Semeniuk (Semenyuk)',
        'Kostiuk (Kostyuk)',
        'Ivanov[Citation Needed]',
        'Humeniuk (Humenyuk)',
        'Vovk',
        'Honcharenko',
        'Yakovenko',
        'Romanenko',
        'Prykhodko',
        'Kozak',
        'Kovtun',
        'Nazarenko',
        'Pylypenko',
        'Honchar',
        'Ishchenko',
        'Fedorenko',
        'Honcharuk',
        'Nesterenko',
        'Kolisnyk',
        'Demchenko',
        'Tereshchenko',
        'Tyshchenko',
        'Babenko',
        'Popovych',
        'Tarasenko',
        'Makarenko',
        'Marchuk',
        'Moskalenko',
        'Zinchenko',
        'Yatsenko',
        'Zhuk',
        'Lytvyn',
        'Herasymenko',
        'Kyrychenko',
        'Kolesnyk',
        'Mishchenko',
        'Tymoshenko',
        'Popova',
        'Fop',
        'Palamarchuk',
        'Vlasenko',
        'Shapoval',
        'Bondarchuk',
        'Lutsenko',
        'Kondratiuk (Kondratyuk)',
        'Chorna',
        'Shcherbyna',
        'Antoniuk (Antonyuk)',
        'Soroka',
        'Suprun'
    ],

    Poland: [
        'Nowak',
        'Kowalski',
        'Wisniewski',
        'Dabrowski',
        'Kaminski',
        'Kowalczyk',
        'Zielinski',
        'Szymanski',
        'Wozniak',
        'Kozlowski',
        'Wojciechowski',
        'Kwiatkowski',
        'Kaczmarek',
        'Piotrowski',
        'Grabowski',
        'Nowakowski',
        'Pawlowski',
        'Michalski',
        'Nowicki',
        'Adamczyk',
        'Dudek',
        'Zajac',
        'Wieczorek',
        'Jablonski',
        'Król',
        'Majewski',
        'Olszewski',
        'Jaworski',
        'Pawlak',
        'Walczak',
        'Górska',
        'Rutkowski',
        'Ostrowski',
        'Duda',
        'Tomaszewski',
        'Jasinski',
        'Zawadzki',
        'Chmielewski',
        'Borkowski',
        'Czarnecki',
        'Sawicki',
        'Sokolowski',
        'Maciejewski',
        'Szczepanski',
        'Kucharski',
        'Kalinowski',
        'Wysocki',
        'Adamski',
        'Sobczak',
        'Czerwinski'
    ],

    PolandF: [
        'Nowak',
        'Kowalska',
        'Wisniewska',
        'Dabrowska',
        'Kaminska',
        'Kowalczyk',
        'Zielinska',
        'Szymanska',
        'Wozniak',
        'Kozlowska',
        'Wojciechowska',
        'Kwiatkowska',
        'Kaczmarek',
        'Piotrowska',
        'Grabowska',
        'Nowakowska',
        'Pawlowska',
        'Michalska',
        'Nowicka',
        'Adamczyk',
        'Dudek',
        'Zajac',
        'Wieczorek',
        'Jablonska',
        'Król',
        'Majewska',
        'Olszewska',
        'Jaworska',
        'Pawlak',
        'Walczak',
        'Górska',
        'Rutkowska',
        'Ostrowska',
        'Duda',
        'Tomaszewska',
        'Jasinska',
        'Zawadzka',
        'Chmielewska',
        'Borkowska',
        'Czarnecka',
        'Sawicka',
        'Sokolowska',
        'Maciejewska',
        'Szczepanska',
        'Kucharska',
        'Kalinowska',
        'Wysocka',
        'Adamska',
        'Sobczak',
        'Czerwinska'
    ],

    Arab: [
        'Abadi',
        'Abboud',
        'Almasi',
        'Amari',
        'Antar',
        'Antoun',
        'Arian',
        'Asfour',
        'Asghar',
        'Asker',
        'Assaf',
        'Aswad',
        'Atiyeh',
        'Attia',
        'Awad',
        'Ba',
        'Baba',
        'Bahar',
        'Basara',
        'Bata',
        'Baz',
        'Bazzi',
        'Bishara',
        'Bitar',
        'Botros',
        'Boulos',
        'Boutros',
        'Cham',
        'Dagher',
        'Daher',
        'Deeb',
        'Essa',
        'Fakhoury',
        'Gaber',
        'Ganem',
        'Ganim',
        'Gerges',
        'Ghanem',
        'Ghannam',
        'Guirguis',
        'Hadad',
        'Haddad',
        'Haik',
        'Hajjar',
        'Hakimi',
        'Halabi',
        'Hanania',
        'Handal',
        'Harb',
        'Isa',
        'Issa',
        'Kalb',
        'Kanaan',
        'Kassab',
        'Kassis',
        'Kattan',
        'Khouri',
        'Khoury',
        'Kouri',
        'Koury',
        'Maalouf',
        'Maloof',
        'Malouf',
        'Mansour',
        'Maroun',
        'Masih',
        'Mifsud',
        'Mikhail',
        'Moghadam',
        'Morcos',
        'Mustafa',
        'Nader',
        'Nahas',
        'Naifeh',
        'Najjar',
        'Naser',
        'Nassar',
        'Nazari',
        'Quraishi',
        'Qureshi',
        'Rahal',
        'Sabbag',
        'Sabbagh',
        'Safar',
        'Said',
        'Salib',
        'Saliba',
        'Samaha',
        'Sarkis',
        'Sarraf',
        'Sayegh',
        'Seif',
        'Shadid',
        'Shalhoub',
        'Shammas',
        'Shamon',
        'Shamoon',
        'Shamoun',
        'Sleiman',
        'Srour',
        'Tahan',
        'Tannous',
        'Toma',
        'Totah',
        'Touma',
        'Tuma',
        'Wasem',
        'Zogby'
    ],

    Pakistan: [
        'Afridi',
        'Ahmadi',
        'Arain',
        'Azizi',
        'Baloch',
        'Bhatti',
        'Butt',
        'Chandyo',
        'Chaudhri',
        'Chodary',
        'Chouhan',
        'Hussaini, Hossaini',
        'Jilani',
        'Jinnah',
        'Khan',
        'Khurshid',
        'Malik',
        'Mohamed, Mohamadi',
        'Muhammad',
        'Orakzai',
        'Qazi',
        'Qureshi',
        'Raja',
        'Rana',
        'Reza',
        'Sabzwari',
        'Shah',
        'Shamsi',
        'Siddiqui',
        'Tiwana',
        'Yosufzai'
    ],

    India: [
        'Kumar',
        'Lal',
        'Sharma',
        'Shan',
        'Jai',
        'Pal',
        'Aggarwal',
        'Raje',
        'Chande',
        'Chander',
        'Nara',
        'Rai',
        'Nath',
        'Goel',
        'Bhat',
        'Raji',
        'Anand',
        'Suri',
        'Kapoor',
        'Chandra',
        'Patel',
        'Verma',
        'Malhotra',
        'Sai',
        'Engineer',
        'Arun',
        'Madan',
        'Srini',
        'Prasad',
        'Sara',
        'Rana',
        'Raman',
        'Subram',
        'Mehta',
        'Subramani',
        'Sethi',
        'Vijaya',
        'Malik',
        'Narayan',
        'Mittal',
        'Nita',
        'Kishore',
        'Roy',
        'Chaudhary',
        'Charan',
        'Nigam',
        'Sen',
        'Subramanian',
        'Nirmal',
        'Pawan',
        'Manohar',
        'Sahni',
        'Lalit',
        'Rajan',
        'Sehgal',
        'Uddin',
        'Saini',
        'Neel',
        'Jana',
        'Darsha',
        'Ranga',
        'Vish',
        'Mehra',
        'Puri',
        'Muthu',
        'Gandhi',
        'Lata',
        'Chauhan',
        'Saxena',
        'Swami',
        'Neela',
        'Chandrasekar',
        'Soni',
        'Dhawan',
        'Nagpal',
        'Joshi',
        'Krishnamurthy',
        'Persaud',
        'Punj',
        'Veena',
        'Mahajan',
        'Manju',
        'Dutta',
        'Sandeep',
        'Narang',
        'Naran',
        'Veer',
        'Tyagi',
        'Samuel',
        'Srivastava',
        'Sudha',
        'Srivas',
        'Mati',
        'Neelam',
        'Rastogi',
        'Bhatt',
        'Rajagopal',
        'Srivastav',
        'Ganesh',
        'Jindal'
    ],

    Philippines: [
        'Alvarez',
        'Aquino',
        'Bautista',
        'Castillo',
        'Castillo',
        'Cruz',
        'De Guzman',
        'De Jesus',
        'De La Cruz',
        'De Leon',
        'De Los Santos',
        'Del Rosario',
        'Delos Reyes',
        'Diaz',
        'Fernandez',
        'Garcia',
        'Gonzales',
        'Guevarra',
        'Hernández',
        'Lopez',
        'Marquez',
        'Mendoza',
        'Mercado',
        'Mercado',
        'Morales',
        'Morales',
        'Navarro',
        'Navarro',
        'Pascual',
        'Perez',
        'Ramos',
        'Reyes',
        'Rivera',
        'Rodriguez',
        'Sanchez',
        'Santos',
        'Villanueva',
        'Villanueva'
    ],

    Indonesia: [
        'Agusalim',
        'Atmadja',
        'Atmadjaja',
        'Budiaman',
        'Budiman',
        'Budiono',
        'Cahyadi (Tahyadi)',
        'Chandra',
        'Darmadi',
        'Darmali',
        'Dharmawijaya',
        'Gunardi',
        'Gunawan',
        'Hadiman',
        'Halim',
        'Hardja',
        'Hartanto',
        'Hartono',
        'Hermanto',
        'Hermawan',
        'Indradjaja (Indrajaya)',
        'Irawan',
        'Iskandar',
        'Jayadi',
        'Johan',
        'Kartawijaya',
        'Kurnia',
        'Kurniawan',
        'Kusnadi',
        'Kusuma',
        'Kusumo',
        'Lesmana',
        'Lesmono',
        'Lie',
        'Makmur',
        'Muljana',
        'Oesman',
        'Pranata',
        'Pranoto',
        'Rachman',
        'Salim',
        'Sanjaya',
        'Santoso',
        'Sasmita',
        'Setiabudi',
        'Setiawan',
        'Sudirman',
        'Sudjarwadi',
        'Sugiarto',
        'Sumadi',
        'Susanto',
        'Susman',
        'Sutedja',
        'Tan',
        'Tanudjaja',
        'Tanuwidjaja',
        'Tedja',
        'Tedjo',
        'Wibowo',
        'Widjaja',
        'Yuwono'
    ],

    Thailand: [
        'Yilmaz',
        'Kaya',
        'Demir',
        'Sahin',
        'Çelik',
        'Yildiz',
        'Yildirim',
        'Öztürk',
        'Aydin',
        'Özdemir',
        'Arslan',
        'Dogan',
        'Kiliç',
        'Aslan',
        'Çetin',
        'Kara',
        'Koç',
        'Kurt',
        'Özkan',
        'Simsek'
    ],

    China: [
        'Cai',
        'Cao',
        'Chang',
        'Chen',
        'Cheng',
        'Cui',
        'Dai',
        'Deng',
        'Ding',
        'Dong',
        'Du',
        'Duan',
        'Fan',
        'Fang',
        'Feng',
        'Fu',
        'Gao',
        'Gong',
        'Gu',
        'Guo',
        'Han',
        'He',
        'He',
        'He',
        'Hou',
        'Hu',
        'Huang',
        'Jia',
        'Jiang',
        'Jiang',
        'Jiang',
        'Jin',
        'Kang',
        'Kong',
        'Lai',
        'Lei',
        'Li',
        'Li',
        'Liang',
        'Liao',
        'Lin',
        'Liu',
        'Long',
        'Lu',
        'Lu',
        'Luo',
        'Lü',
        'Ma',
        'Mao',
        'Meng',
        'Pan',
        'Peng',
        'Qian',
        'Qiao',
        'Qin',
        'Qiu',
        'Ren',
        'Shao',
        'Shen',
        'Shi',
        'Shi',
        'Song',
        'Su',
        'Sun',
        'Tan',
        'Tang',
        'Tang',
        'Tian',
        'Wan',
        'Wang',
        'Wang',
        'Wei',
        'Wen',
        'Wu',
        'Wu',
        'Xia',
        'Xiao',
        'Xie',
        'Xiong',
        'Xu',
        'Xu',
        'Xue',
        'Yan',
        'Yang',
        'Yao',
        'Ye',
        'Yi',
        'Yin',
        'Yu',
        'Yu',
        'Yuan',
        'Zeng',
        'Zhang',
        'Zhao',
        'Zhen',
        'Zhong',
        'Zhou',
        'Zhu',
        'Zou'
    ],

    Korea: [
        'Kim',
        'Lee',
        'Park',
        'Choi',
        'Jeong',
        'Kang',
        'Cho',
        'Yoon',
        'Jang',
        'Lim',
        'Han',
        'O',
        'Shin',
        'Seo',
        'Kwon',
        'Hwang',
        'Ahn',
        'Song',
        'Hong',
        'Jun',
        'Ko',
        'Moon',
        'Son',
        'Yang',
        'Bae',
        'Pak',
        'Jo',
        'Heo',
        'Nam',
        'Sim',
        'Yu',
        'No',
        'Ha',
        'Jeon',
        'Gwak',
        'Seong',
        'Cha',
        'Yu',
        'Gu',
        'Woo',
        'Choo',
        'Yim',
        'Na',
        'Shin',
        'Min',
        'Chen',
        'Ji'
    ],

    Japan: [
        'Sato',
        'Suzuki',
        'Takahashi',
        'Tanaka',
        'Watanabe',
        'Ito',
        'Nakamura',
        'Kobayashi',
        'Yamamoto',
        'Kato',
        'Yoshida',
        'Yamada',
        'Sasaki',
        'Yamaguchi',
        'Matsumoto',
        'Inoue',
        'Kimura',
        'Shimizu'
    ],

    Africa: [
        'Ademola',
        'Adesina',
        'Aguda',
        'Akenzua',
        'Akerele',
        'Alakija',
        'Anenih',
        'Atttah',
        'Azikwe',
        'Ba',
        'Bah',
        'Ballo',
        'Balogun',
        'Bello',
        'Biobaku',
        'Boro',
        'Chahine',
        'Chukwumereije',
        'Cisse',
        'Congo',
        'Contee',
        'Conteh',
        'Dia',
        'Diallo',
        'Diop',
        'Diya',
        'Egwu',
        'Ekwensi',
        'Ezekwesili',
        'Fagbure',
        'Falana',
        'Fall',
        'Fofana',
        'Fofana',
        'Gbadamosi',
        'Gueye',
        'Ibori',
        'Igwe',
        'Iweala',
        'Iwu',
        'Jaja',
        'Jakande',
        'Jalloh',
        'Jang',
        'Kalu',
        'Keita',
        'Kone',
        'Maalouf',
        'Madaki',
        'Magoro',
        'Mbanefo',
        'Mensah',
        'Ndiaye',
        'Nwosu',
        'Nzeogwu',
        'Obasanjo',
        'Obi',
        'Odili',
        'Ohakim',
        'Okafor',
        'Okeke',
        'Okilo',
        'Okonjo',
        'Okoro',
        'Okoye',
        'Okpara',
        'Olanrewaju',
        'Onobanjo',
        'Onwuatuegwu',
        'Onwudiwe',
        'Orji',
        'Osei',
        'Owusu',
        'Oyenusi',
        'Oyinlola',
        'Sall',
        'Sane',
        'Sarr',
        'Sesay',
        'Solarin',
        'Sow',
        'Soyinka',
        'Sy',
        'Sylla',
        'Tinibu',
        'Toure',
        'Traore',
        'Turay',
        'Uba',
        'Yeboah'
    ],

    Norway: [
        'Haug/Hauge/Haugn',
        'Haugland',
        'Andersen/Andresen/Andreassen',
        'Arnesen',
        'Karlsen',
        'Bjerke',
        'Bjørnstad',
        'Aune',
        'Lund/Lunde',
        'Ali',
        'Brekke',
        'Han(s)sen/Johansen',
        'Nielsen/Nilsen',
        'Mathisen',
        'Edvardsen',
        'Jacobsen/Jakobsen',
        'Wold',
        'Mohamed',
        'Foss',
        'Gran',
        'Hammer',
        'Thoresen',
        'Løken',
        'Solbakken',
        'Solvang',
        'Steen/Stene',
        'Hagen',
        'Fjeld',
        'Rørvik',
        'Antonsen',
        'Lie/Lien',
        'Berg/Berge',
        'Berntsen',
        'Sæther',
        'Jensen/Jenssen',
        'Paulsen',
        'Engen',
        'Ellingsen/Erlingsen',
        'Gulbrandsen',
        'Halvorsen',
        'Thorsen',
        'Holm',
        'Nygård/Ødegård',
        'Olsen',
        'Abrahamsen',
        'Johnsen',
        'Danielsen',
        'Næss',
        'Knudsen/Knutsen',
        'Sørensen',
        'Kristensen/Kristiansen',
        'Christensen/Christiansen',
        'Kristoffersen',
        'Birkeland',
        'Larsen',
        'Eide',
        'Martinsen',
        'Pedersen',
        'Myhre',
        'Amundsen',
        'Rasmussen',
        'Helland',
        'Eriksen',
        'Aas/Aasen',
        'Fredriksen',
        'Pettersen',
        'Gundersen',
        'Mikkelsen',
        'Eliassen',
        'Bakke/Bakken',
        'Isaksen',
        'Madsen',
        'Sivertsen',
        'Sandvik',
        'Strand',
        'Rønning',
        'Solberg/Solheim',
        'Henriksen',
        'Bøe',
        'Strøm',
        'Myklebust',
        'Tangen',
        'Thomassen',
        'Tveit',
        'Skoglund',
        'Dahl',
        'Bugge',
        'Moe/Moen',
        'Nguyen',
        'Vik',
        'Johannessen',
        'Kvam',
        'Møller',
        'Eggen',
        'Løkken',
        'Aamodt',
        'Tran',
        'Sand',
        'Bolstad',
        'Grande'
    ],

    Western: [
        'Díaz',
        'Morán',
        'González',
        'Morales',
        'Sánchez',
        'Ramírez',
        'Pérez',
        'Calderón',
        'Gutiérrez',
        'Smith',
        'Johnson',
        'Williams',
        'Brown',
        'Jones',
        'Miller',
        'Davis',
        'García',
        'Rodríguez',
        'Wilson',
        'Martínez',
        'Taylor',
        'Thomas',
        'Hernández',
        'Moore',
        'Martin',
        'Jackson',
        'Thompson',
        'White',
        'López',
        'Müller',
        'Schmidt',
        'Schneider',
        'Fischer',
        'Weber',
        'Schäfer',
        'Meyer',
        'Wagner',
        'Becker',
        'Bauer',
        'Hoffmann',
        'De Jong',
        'De Vries',
        'Van den Berg',
        'Van Dijk',
        'Bakker',
        'Janssen',
        'Visser',
        'Smit',
        'Meijer',
        'Johansson',
        'Andersson',
        'Karlsson',
        'Nilsson',
        'Eriksson',
        'Hansen',
        'Olsen',
        'Larsen',
        'Pedersen',
        'Kristiansen',
        'Jensen',
        'Johnsen',
        'Pettersen',
        'Bianchi',
        'Coppola',
        'Farina',
        'Ferrari',
        'Giordano',
        'Leone',
        'Mariani',
        'Pellegrino',
        'Bernard',
        'Dubois',
        'Petit',
        'Durand',
        'Laurent',
        'Lefebvre',
        'Michel',
        'Fournier',
        'Morel',
        'Girard',
        'Akimov',
        'Baranov',
        'Belyakov',
        'Frolov',
        'Grigorev',
        'Nowak',
        'Kowalski',
        'Yudin',
        'Titov',
        'Sokolov',
        'Semenov',
        'Wozniak',
        'Lebedev',
        'Larionov',
        'Boyko',
        'Koval',
        'Bondar',
        'Rudenko',
        'Shvets',
        'Harvey'
    ],

    Asian: [
        'Cai',
        'Chang',
        'Cui',
        'Ding',
        'Dong',
        'Fan',
        'Feng',
        'Gong',
        'Han',
        'He',
        'Huang',
        'Jiang',
        'Jin',
        'Kong',
        'Li',
        'Liang',
        'Liu',
        'Ma',
        'Mao',
        'Pan',
        'Qian',
        'Shen',
        'Shi',
        'Sun',
        'Tang',
        'Tian',
        'Wang',
        'Wu',
        'Xia',
        'Xie',
        'Xiong',
        'Xu',
        'Yang',
        'Yao',
        'Yin',
        'Zhang',
        'Zhen',
        'Zhou',
        'Zou',
        'Kumar',
        'Lal',
        'Sharma',
        'Shan',
        'Jai',
        'Pal',
        'Aggarwal',
        'Raje',
        'Chander',
        'Nara',
        'Rai',
        'Nath',
        'Goel',
        'Bhat',
        'Raji',
        'Anand',
        'Suri',
        'Kapoor',
        'Chandra',
        'Patel',
        'Verma',
        'Malhotra',
        'Sai',
        'Arun',
        'Madan',
        'Srini',
        'Prasad',
        'Nakashima',
        'Mori',
        'Abe',
        'Ikeda',
        'Hashimoto',
        'Ishikawa',
        'Yamashita',
        'Ogawa',
        'Ishii',
        'Hasegawa',
        'Okada',
        'Kondō',
        'Maeda',
        'Fujita',
        'Kim',
        'Lee',
        'Park',
        'Choi',
        'Jeong',
        'Agusalim',
        'Budiono',
        'Halim',
        'Iskandar',
        'Kurnia',
        'Makmur',
        'Pranoto',
        'Setiabudi',
        'Tedja',
        'Kara',
        'Yılmaz',
        'Aydın',
        'Aslan',
        'Kurt',
        'Şahin'
    ],

    African: [
        'Ademola',
        'Aguda',
        'Akerele',
        'Anenih',
        'Ba',
        'Balogun',
        'Boro',
        'Cisse',
        'Conteh',
        'Diop',
        'Diya',
        'Egwu',
        'Ezekwesili',
        'Fagbure',
        'Fall',
        'Fofana',
        'Gbadamosi',
        'Gueye',
        'Ibori',
        'Iweala',
        'Iwu',
        'Jaja',
        'Jakande',
        'Jalloh',
        'Kalu',
        'Kone',
        'Magoro',
        'Mbanefo',
        'Ndiaye',
        'Nzeogwu',
        'Obi',
        'Odili',
        'Okafor',
        'Okeke',
        'Okilo',
        'Okonjo',
        'Okoro',
        'Okoye',
        'Okpara',
        'Olanrewaju',
        'Onobanjo',
        'Onwuatuegwu',
        'Onwudiwe',
        'Orji',
        'Osei',
        'Owusu',
        'Oyenusi',
        'Oyinlola',
        'Sall',
        'Sane',
        'Sarr',
        'Sesay',
        'Solarin',
        'Sow',
        'Soyinka',
        'Sy',
        'Sylla',
        'Tinibu',
        'Toure',
        'Traore',
        'Turay',
        'Uba',
        'Yeboah',
        'Abadi',
        'Amari',
        'Arian',
        'Asker',
        'Atiyeh',
        'Ba',
        'Basara',
        'Bazzi',
        'Botros',
        'Cham',
        'Deeb',
        'Gaber',
        'Gerges',
        'Guirguis',
        'Hajjar',
        'Hanania',
        'Issa',
        'Kassab',
        'Khouri',
        'Koury',
        'Maalouf',
        'Mansour',
        'Mifsud',
        'Moghadam',
        'Morcos',
        'Mustafa',
        'Naifeh',
        'Nassar',
        'Qureshi',
        'Sabbag',
        'Safar',
        'Saliba',
        'Sarkis',
        'Seif',
        'Shammas',
        'Shamoun',
        'Tahan'
    ]
};
