import shuffle from 'lodash-es/shuffle';
import difference from 'lodash-es/difference';

import store from '@/store';
import { pickUniqueRandomItems } from '@/modules/memogenius-game/utils';
import namesService from '@/modules/system-name/service';

import hobbies from './data/hobbies';
import professions from './data/professions';
import facesService from './faces-service';

function randomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

function chunckArrayByRelativeFractions(arr, fractions) {
    const chunks = [];
    const numberOfChunks = fractions.length;
    const total = arr.length;
    const sum = fractions.reduce((a, b) => a + b, 0);
    const scale = 1 / sum;

    let index = 0;
    for (let chunkIndex = 0; chunkIndex < numberOfChunks; chunkIndex++) {
        const chunkSize = Math.floor(total * fractions[chunkIndex] * scale);
        chunks.push(arr.slice(index, chunkIndex < numberOfChunks - 1 ? index + chunkSize : total));
        index += chunkSize;
    }
    return chunks;
}

function getItem({ faceId, name, surname, profession, hobby, country, race, gender, age }, availableMap) {
    const language = store.getters['moduleApp/locale'];
    const languageIndex = language === 'no' ? 1 : 0;

    if (country && !race) {
        race = namesService.getRace(country);
    }
    let image;
    ({ image, gender, race, age, faceId } = facesService.getFace({ faceId, gender, race, age }, availableMap));

    const item = {
        image,
        gender,
        faceId,
        race
    };

    if (name) {
        ({ name, gender, country } = namesService.getName({ name, gender, country }));
        item.name = name;
        item.nameTest = { done: false, score: 0 };
    }
    if (surname) {
        ({ surname, gender, country } = namesService.getSurname({ surname, gender, country }));
        item.surname = surname;
        item.surnameTest = { done: false, score: 0 };
    }
    if (profession) {
        item.profession = randomItem(professions)[languageIndex];
        item.professionTest = { done: false, score: 0 };
    }
    if (hobby) {
        item.hobby = randomItem(hobbies)[languageIndex];
        item.hobbyTest = { done: false, score: 0 };
    }
    item.gender = gender;
    item.country = country;
    return item;
}

class QuestionService {
    constructor() {
        this.language = store.getters['moduleApp/locale'];
        this.languageIndex = this.language === 'no' ? 1 : 0;
    }

    load() {
        if (!this.loadPromise) {
            this.loadPromise = new Promise(resolve => {
                facesService.load().then(resolve);
            });
        }
        return this.loadPromise;
    }

    getItems(numberOfItems, { countries, includeName, includeSurname, includeProfession, includeHobby, items }) {
        const itemsOptions = Array(numberOfItems);
        for (let i = 0; i < numberOfItems; i++) {
            itemsOptions[i] = {
                name: includeName,
                surname: includeSurname,
                profession: includeProfession,
                hobby: includeHobby
            };
        }

        if (countries) {
            chunckArrayByRelativeFractions(
                itemsOptions,
                countries.map(x => x.fraction)
            )
                .map((arr, i) => {
                    const country = countries[i].country;
                    const races = namesService.generateRacesForCountry(country, arr.length);
                    return races.map(race => ({
                        race,
                        country
                    }));
                })
                .reduce((a, b) => a.concat(b), [])
                .forEach(({ race, country }, i) => {
                    itemsOptions[i].race = race;
                    itemsOptions[i].country = country;
                });
        }

        if (items) {
            items.forEach((item, index) => {
                if (itemsOptions[index]) {
                    Object.assign(itemsOptions[index], item);
                }
            });
        }

        const state = store.state.moduleGameFaces.questionServiceState;
        const usedMap = JSON.parse(JSON.stringify(state.usedMap || {}));
        const availableMap = {};
        Object.entries(facesService.raceMap).forEach(([race, faces]) => {
            const used = usedMap[race] || [];
            const all = faces.map(face => face.id);
            const available = difference(all, used);
            availableMap[race] = shuffle(available).concat(used);
        });

        const result = itemsOptions.map(options => getItem(options, availableMap));

        result.forEach(({ race, faceId }) => {
            if (!usedMap[race]) {
                usedMap[race] = [];
            }
            usedMap[race].push(faceId);
        });

        Object.entries(usedMap).forEach(([race, faceIds]) => {
            const maxHistorySize = Math.floor(facesService.raceMap[race].length / 2);
            if (faceIds.length > maxHistorySize) {
                usedMap[race] = faceIds.slice(faceIds.length - maxHistorySize);
            }
        });
        store.dispatch('moduleGameFaces/updateQuestionServiceState', { usedMap });

        return result;
    }

    getFirstNameSplits(numberOfFragments, include = [], country, gender) {
        return namesService.getNameFragments(numberOfFragments, include, country, gender);
    }

    getSurnameSplits(numberOfFragments, include = [], country, gender) {
        return namesService.getSurnameFragments(numberOfFragments, include, country, gender);
    }

    getProfessions(numberOfOptions, include = []) {
        const options = pickUniqueRandomItems(professions, numberOfOptions).map(x => x[this.languageIndex]);
        include.forEach(option => {
            if (options.indexOf(option) === -1) {
                options.push(option);
                options.shift();
            }
        });
        return shuffle(options);
    }

    getHobbies(numberOfOptions, include = []) {
        const options = pickUniqueRandomItems(hobbies, numberOfOptions).map(x => x[this.languageIndex]);
        include.forEach(option => {
            if (options.indexOf(option) === -1) {
                options.push(option);
                options.shift();
            }
        });
        return shuffle(options);
    }
}

const questionService = new QuestionService();
export default questionService;
