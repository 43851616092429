import loadImage from 'image-promise';
import generateSessionReport from '@/modules/memogenius-game/generateSessionReport';

import questionService from './question-service';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    async generateMemorize() {
        const {
            numberOfItems,
            includeSurname,
            includeProfession,
            includeHobby,
            itemMemorizeTimeLimit,
            countryOptions,
            items = null
        } = this.options;
        const memorizeTimeLimit = itemMemorizeTimeLimit * numberOfItems;

        await questionService.load();
        const faceItems = questionService.getItems(numberOfItems, {
            countries: countryOptions,
            includeFace: true,
            includeName: true,
            items,
            includeSurname,
            includeHobby,
            includeProfession
        });

        await loadImage(faceItems.map(item => item.image)).catch(function (err) {
            console.error('One or more images have failed to load :('); // eslint-disable-line no-console
            console.error(err.errored); // eslint-disable-line no-console
            console.info('But these loaded fine:'); // eslint-disable-line no-console
            console.info(err.loaded); // eslint-disable-line no-console
        });

        return {
            items: faceItems.map(faceItem => {
                return {
                    memorizeItemType: 'GameFaces-MemorizeItem',
                    timeLimit: itemMemorizeTimeLimit,
                    data: faceItem
                };
            }),
            timeLimit: memorizeTimeLimit,
            timeoutPerItem: true
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    async generateQuiz() {
        const { numberOfItems, itemQuizTimeLimit } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * numberOfItems;

        const items = this.memorizeReport ? this.memorizeReport.items : this.generateMemorize().items;
        const sourceItems = items.map((item, i) => {
            return {
                quizItemType: 'GameFaces-QuizItem',
                data: item.data,
                memorizeTime: item.timeUsed
            };
        });

        await loadImage(sourceItems.map(item => item.data.image)).catch(function (err) {
            console.error('One or more images have failed to load :('); // eslint-disable-line no-console
            console.error(err.errored); // eslint-disable-line no-console
            console.info('But these loaded fine:'); // eslint-disable-line no-console
            console.info(err.loaded); // eslint-disable-line no-console
        });

        return {
            sourceItems,
            items: [],
            timeLimit: quizTimeLimit,
            lives: 3
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { numberOfItems, stars2, stars3 } = this.options;

        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;
        let stars = 0;
        // NOTE: should not give stars if score < 1. But score wrongely reported, so ignored
        if (memorizeTime) {
            if (memorizeTime < stars3 * numberOfItems * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * numberOfItems * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }

        this.quizReport = { items, timeUsed, score, sourceItems, stars };
    }
    generateReport() {
        return generateSessionReport({
            memorizeReport: this.memorizeReport,
            quizReport: this.quizReport,
            initial(item, sourceItem) {
                return { type: 'image', value: sourceItem.data.image };
            },
            attempt(test) {
                return {
                    value: test.submittedAnswer,
                    score: test.score
                };
            }
        });
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
    }
}
