import QuizItem from '@/modules/games-shared/components/QuizItem';

import Session from './Session';
import questionService from './question-service';
import Card from './components/MemorizeItem';
import MemorizeItemInstruction from './components/MemorizeItemInstruction';
import Question from './components/Question';
import NameTest from './components/NameTest';
import SurnameTest from './components/SurnameTest';
import ProfessionTest from './components/ProfessionTest';
import HobbyTest from './components/HobbyTest';

const quizMapping = {
    'GameFaces-MemorizeItem': {
        component: Card,
        instructionComponent: MemorizeItemInstruction
    },
    'GameFaces-QuizItem': {
        component: QuizItem,
        questionComponent: Question,
        generateTestNames(data) {
            const testNames = [];
            if (data.name) {
                testNames.push('nameTest');
            }
            if (data.surname) {
                testNames.push('surnameTest');
            }
            if (data.profession) {
                testNames.push('professionTest');
            }
            if (data.hobby) {
                testNames.push('hobbyTest');
            }
            return testNames;
        },
        tests: {
            nameTest: {
                component: NameTest,
                generateTestData(data) {
                    const fasit = data.name.toLowerCase();
                    const fragments = questionService.getFirstNameSplits(12, [fasit], data.country, data.gender);
                    return {
                        fasit,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 2,
                        fragments
                    };
                },
                calculateScore(data, answer) {
                    return data.name.toLowerCase() === answer ? 1 : 0;
                }
            },
            surnameTest: {
                component: SurnameTest,
                generateTestData(data) {
                    const fasit = data.surname.toLowerCase();
                    const fragments = questionService.getSurnameSplits(12, [fasit], data.country, data.gender);
                    return {
                        fasit,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 2,
                        fragments
                    };
                },
                calculateScore(data, answer) {
                    return data.surname.toLowerCase() === answer ? 1 : 0;
                }
            },
            professionTest: {
                component: ProfessionTest,
                generateTestData(data) {
                    const options = questionService.getProfessions(4, [data.profession]);
                    return {
                        fasit: data.profession,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options
                    };
                },
                calculateScore(data, answer) {
                    return data.profession === answer ? 1 : 0;
                }
            },
            hobbyTest: {
                component: HobbyTest,
                generateTestData(data) {
                    const options = questionService.getProfessions(4, [data.hobby]);
                    return {
                        fasit: data.hobby,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options
                    };
                },
                calculateScore(data, answer) {
                    return data.hobby === answer ? 1 : 0;
                }
            }
        }
    }
};

export default {
    moduleName: 'moduleGameFaces',
    Session,
    quizMapping
};
