export default {
    USA: [
        'Genesis',
        'Isabella/bella',
        'Abigail',
        'Ava/Avery',
        'Alexa/Alexis/Alexandra',
        'Autumn',
        'Amelia',
        'Caroline',
        'Katherine/Kaitlyn',
        'Chloe/Khloe',
        'Skylar',
        'Faith',
        'Patricia',
        'Dorothy',
        'Layla',
        'Hailey',
        'Maria/Mary',
        'Hannah',
        'Harper',
        'Valentina',
        'Eva/Eve/Evelyn',
        'Lydia',
        'Lily/Lillian',
        'Elizabeth',
        'Luciana/Lucy',
        'Alice/Alison/Alyssa',
        'Rosalie',
        'Zoé',
        'Scarlett',
        'Samantha',
        'Piper',
        'Stella',
        'Savannah',
        'Ashley',
        'Esther',
        'Emily',
        'Olivia',
        'Barbara',
        'Addison',
        'London',
        'Margaret',
        'Brooklyn',
        'Camilla',
        'Charlotte',
        'Chana',
        'Valeria',
        'Grace',
        'Aaliyah',
        'Lea',
        'Sadie',
        'Emma',
        'Ella/Ellie',
        'Florence',
        'Maya',
        'Serenity',
        'Brianna',
        'Julia/July/Juliette',
        'Lauren',
        'Penelope',
        'Ruby',
        'Sara',
        'Taylor',
        'Sydney',
        'Neveah',
        'Paisley',
        'Violet',
        'Kennedy',
        'Victoria',
        'Claire',
        'Jennifer',
        'Anna/Annabelle',
        'Aria/Ariana',
        'Aubrey/Audrey',
        'Chaya',
        'Gabriella',
        'Gianna',
        'Jocelyn',
        'Kayla/Kaylee/Kylie',
        'Linda',
        'Mackenzie',
        'Madeline/Madelyn',
        'Madison',
        'Makayla',
        'Martina',
        'Melanie',
        'Mia',
        'Miriam',
        'Morgan',
        'Naomi',
        'Natalie',
        'Nicole',
        'Nora',
        'Peyton',
        'Rachel',
        'Riley',
        'Sophie/Sophia',
        'Susan'
    ],

    Spain: [
        'Abril',
        'Agustina',
        'Agustina',
        'Aida',
        'Aina',
        'Ainhoa',
        'Alba',
        'Alejandra',
        'Alexandra',
        'Alice',
        'Alicia',
        'Alma',
        'Almudena',
        'Amanda',
        'Ana',
        'Andrea',
        'Angela',
        'Anna',
        'Antonella',
        'Ariana',
        'Bea',
        'Beatriz',
        'Belén',
        'Blanca',
        'Camila',
        'Carla',
        'Carla',
        'CARMEN',
        'Catalina',
        'Celia',
        'Clara',
        'Clàudia',
        'Cris',
        'Cristina',
        'Daniela',
        'Diana',
        'Elena',
        'Emilia',
        'Estefania',
        'Esther',
        'Eva',
        'Fatima',
        'Fernanda',
        'Florencia',
        'Gema',
        'Gloria',
        'Helena',
        'Inés',
        'Inma',
        'Irati',
        'Irene',
        'Isa',
        'Isabel',
        'Isabella',
        'Isidora',
        'Jennifer',
        'Jessica',
        'Judit',
        'Judith',
        'Julia',
        'Julia',
        'Julieta',
        'Laia',
        'Lara',
        'Laura',
        'Leire',
        'Leticia',
        'Lidia',
        'Lorena',
        'Lucía',
        'Luciana',
        'Luna',
        'Luz',
        'Macarena',
        'Maite',
        'Maite',
        'Mar',
        'Maria',
        'Maria Jose',
        'Mariana',
        'Marina',
        'Marta',
        'Martina',
        'Mary',
        'Mía',
        'Micaela',
        'Milagros',
        'Mireia',
        'Miriam',
        'Monica',
        'Naiara',
        'Natalia',
        'Nerea',
        'Nuria',
        'Paloma',
        'Paola',
        'Patri',
        'Patricia',
        'Paula',
        'Raquel',
        'Regina',
        'Renata',
        'Rocío',
        'Romina',
        'ROSA',
        'Rosario',
        'Samantha',
        'Sandra',
        'Sara',
        'Sara',
        'Silvia',
        'Sofía',
        'Sofía',
        'Sonia',
        'Stella',
        'Susana',
        'Tamara',
        'Tania',
        'Teresa',
        'Uxue',
        'Valentina',
        'Valeria',
        'Vanessa',
        'Verónica',
        'Victoria',
        'Virginia',
        'Ximena',
        'Yaiza'
    ],

    Portugal: [
        'Alana',
        'Alessandra',
        'Aléxia',
        'Alice',
        'Aline',
        'Amanda',
        'Ana',
        'Ana Beatriz',
        'Ana Carolina',
        'Ana Clara',
        'Ana Luiza',
        'Ana Paula',
        'Andressa',
        'Anna',
        'Babi',
        'Bárbara',
        'Beatriz',
        'Bianca',
        'Brenda',
        'Bruna',
        'Camila',
        'Carla',
        'Carol',
        'Carolina',
        'Caroline',
        'Clara',
        'Cristina',
        'Daiane',
        'Daniela',
        'Dara',
        'Debora',
        'Erika',
        'Fabiana',
        'Fernanda',
        'Flavia',
        'Gabi',
        'Gabriela',
        'Gabrielle',
        'Gaby',
        'Giovanna',
        'Helena',
        'Inês',
        'Isabella',
        'Janaina',
        'Jessica',
        'Julia',
        'Juliana',
        'Karina',
        'Kelly',
        'Lais',
        'Lara',
        'Larissa',
        'Laura',
        'Layla',
        'Leonor',
        'Leticia',
        'Lívia',
        'Luana',
        'Luciana',
        'Luisa',
        'Luiza',
        'Maisa',
        'Manuela',
        'Marcela',
        'Margarida',
        'Maria',
        'Maria Clara',
        'Maria Eduarda',
        'Mariana',
        'MARIANE',
        'Mary',
        'Matilde',
        'Mayara',
        'Michele',
        'Milena',
        'Monique',
        'Natália',
        'Nathalia',
        'Nayara',
        'Nicole',
        'Paloma',
        'Pamela',
        'Patricia',
        'Paula',
        'Rafaela',
        'Raissa',
        'Raquel',
        'Regina',
        'Roberta',
        'Sabrina',
        'Sara',
        'Sarah',
        'Sophia',
        'Stephanie',
        'Tainara',
        'Taynara',
        'Thaís',
        'Valentina',
        'Vanessa',
        'Veronica',
        'Victória',
        'Vitoria',
        'Yasmin',
        'Yumi'
    ],

    Germany: [
        'Alina',
        'Amelie',
        'Amy',
        'Anna',
        'Annabell',
        'Annika',
        'Anny',
        'Antonia',
        'Ayleen',
        'Celina',
        'Charlotte',
        'Clara',
        'Elena',
        'Elina',
        'Elisa',
        'Ella',
        'Emely',
        'Emilia',
        'Emily',
        'Emma',
        'Eva',
        'Fabienne',
        'Female',
        'Finnja',
        'Fiona',
        'Franziska',
        'Frida',
        'Greta',
        'Hanna',
        'Helena',
        'Helene',
        'Ida',
        'Isabella',
        'Isabelle',
        'Jana',
        'Jasmin',
        'Joelina',
        'Johanna',
        'Josefine',
        'Jule',
        'Julia',
        'Karla',
        'Karlotta',
        'Katharina',
        'Kiara',
        'Lara',
        'Laura',
        'Leah',
        'Leila',
        'Lena',
        'Leni',
        'Leonie',
        'Lilly',
        'Lina',
        'Linda',
        'Lisa',
        'Lotta',
        'Lucie',
        'Luisa',
        'Luise',
        'Luna',
        'Lya',
        'Magdalena',
        'Marah',
        'Maria',
        'Marie',
        'Marlene',
        'Martha',
        'Mathilda',
        'Maya',
        'Mayla',
        'Melina',
        'Melissa',
        'Merle',
        'Mia',
        'Mila',
        'Milena',
        'Mina',
        'Mira',
        'Miriam',
        'Neele',
        'Nina',
        'Nora',
        'Paula',
        'Paulina',
        'Pauline',
        'Pia',
        'Romy',
        'Ronja',
        'Samira',
        'Sara',
        'Selina',
        'Sinah',
        'Sofie',
        'Sophia',
        'Stella',
        'Theresa',
        'Vanessa',
        'Viktoria',
        'Zoey'
    ],

    Netherlands: [
        'Emma',
        'Sophie',
        'Julia',
        'Anna',
        'Lisa',
        'Isa',
        'Eva',
        'Saar',
        'Lotte',
        'Tess',
        'Lynn',
        'Fleur',
        'Sara',
        'Lieke',
        'Noa',
        'Fenna',
        'Sarah',
        'Mila',
        'Sanne',
        'Roos',
        'Elin',
        'Zoë',
        'Evi',
        'Maud',
        'Jasmijn',
        'Femke',
        'Nina',
        'Anne',
        'Noor',
        'Amy',
        'Sofie',
        'Olivia',
        'Feline',
        'Liv',
        'Esmee',
        'Nora',
        'Iris',
        'Lina',
        'Luna',
        'Naomi',
        'Elise',
        'Amber',
        'Yara',
        'Charlotte',
        'Lana',
        'Milou',
        'Isabel',
        'Isabella',
        'Floor',
        'Lara',
        'Anouk',
        'Fenne',
        'Vera',
        'Nikki',
        'Loïs',
        'Liz',
        'Maria',
        'Tessa',
        'Jill',
        'Laura',
        'Puck',
        'Sophia',
        'Hannah',
        'Evy',
        'Lizzy',
        'Fay',
        'Veerle',
        'Bente',
        'Nienke',
        'Linde',
        'Romy',
        'Senna',
        'Isis',
        'Bo',
        'Sterre',
        'Benthe',
        'Lauren',
        'Julie',
        'Norah',
        'Merel',
        'Ilse',
        'Marit',
        'Nova',
        'Rosalie',
        'Lena',
        'Fiene',
        'Lise',
        'Demi',
        'Johanna',
        'Suze',
        'Vajèn',
        'Ella',
        'Mirthe',
        'Lola',
        'Indy',
        'Emily',
        'Kiki',
        'Sofia'
    ],

    Sweden: [
        'Alice',
        'Maja',
        'Elsa',
        'Ella',
        'Julia',
        'Ebba',
        'Alicia',
        'Olivia',
        'Alva',
        'Wilma',
        'Agnes',
        'Lilly',
        'Klara',
        'Emma',
        'Molly',
        'Isabelle',
        'Linnea',
        'Freja',
        'Saga',
        'Nellie',
        'Ellen',
        'Stella',
        'Emilia',
        'Nova',
        'Vera',
        'Alma',
        'Astrid',
        'Signe',
        'Ester',
        'Selma',
        'Moa',
        'Ellie',
        'Elin',
        'Ida',
        'Isabella',
        'Leah',
        'Amanda',
        'Felicia',
        'Elvira',
        'Sara',
        'Tyra',
        'Sofia',
        'Ines',
        'Meja',
        'Matilda',
        'Lova',
        'Lovisa',
        'Tuva',
        'Siri',
        'Liv',
        'Hanna',
        'Nora',
        'Tilde',
        'Edith',
        'Thea',
        'Sigrid',
        'Elise',
        'Melissa',
        'Juni',
        'Cornelia',
        'Iris',
        'Ingrid',
        'Leia',
        'Svea',
        'Stina',
        'Nathalie',
        'Filippa',
        'Emelie',
        'Minna',
        'Ronja',
        'Livia',
        'Tilda',
        'Majken',
        'M',
        'Tindra',
        'Greta',
        'Hilda',
        'Lykke',
        'Jasmine',
        'Mira',
        'Elina',
        'Emmy',
        'Joline',
        'Hedda',
        'Novalie',
        'Lisa',
        'Linn',
        'Hilma',
        'Vilda',
        'Josefine',
        'Ellinor',
        'Rut',
        'Anna',
        'Maria',
        'Victoria',
        'Sally',
        'Melina',
        'Nicole',
        'Tove'
    ],

    Norway: [
        'Ella',
        'Alva',
        'Andrea',
        'Andrine',
        'Ann/Anna/Anne',
        'Annette',
        'Anja',
        'Astrid',
        'Karoline',
        'Marte',
        'Vibeke',
        'Bente',
        'Mie/Mia',
        'Jessica',
        'Bjørg',
        'Bodil',
        'Alexandra',
        'Ingeborg',
        'Ingvild',
        'Guro',
        'Ida',
        'Kari',
        'Vanja',
        'Yvonne',
        'Wenche',
        'Eva',
        'Lisa',
        'Amalie',
        'Mette',
        'Hilde',
        'Line',
        'Elisabeth',
        'Beate',
        'Jannike',
        'Tone',
        'Torild',
        'Hannah',
        'Hanne',
        'Jeanette',
        'Emilie',
        'Emily',
        'Helene',
        'Merete',
        'Hedda',
        'Liv/Live',
        'Vilde',
        'T(h)erese',
        'Ina',
        'Siri',
        'Julie',
        'Grete',
        'Camilla',
        'Katrine',
        'Hege',
        'Mari/Maria/Marie',
        'Mari(t)/Margrete',
        'Christine',
        'Kristin/Kristina/Kristine',
        'Britt',
        'Karianne',
        'Karine',
        'Lene',
        'Susanne',
        'Linnea',
        'Louise',
        'Emma',
        'Ellen',
        'Maren',
        'Marianne',
        'Maya',
        'Synne/Synnøve',
        'Nina',
        'Olivia',
        'Åse/Åsne',
        'Ester',
        'Isabelle',
        'Malin',
        'Linda',
        'Bettina',
        'Vilgunn',
        'Inger',
        'Ingrid',
        'Mathilde',
        'Ulrika',
        'Cecilie',
        'Carina',
        'Charlotte',
        'Lise',
        'Benedikte',
        'Leah',
        'Janne',
        'Solveig/Solfrid',
        'Nora',
        'Ingunn',
        'Amanda',
        'Stine',
        'Elin',
        'Natalia/Natalie',
        'Karen',
        'Renathe'
    ],

    Denmark: [
        'Emma',
        'Sarah',
        'Cecilie',
        'Julie',
        'Maria',
        'Anna',
        'Ida',
        'Laura',
        'Kathrine',
        'Sofie',
        'Emilie',
        'Freja',
        'Line',
        'Katrine',
        'Caroline',
        'Kristina',
        'Rikke',
        'Sara',
        'Louise',
        'Christina',
        'Helena',
        'Trine',
        'Stine',
        'Simone',
        'Camilla',
        'Signe',
        'Mathilde',
        'Nanna',
        'Nadia',
        'Michelle',
        'Amalie',
        'Sille',
        'Astrid',
        'Rebecca',
        'Josephine',
        'Helene',
        'Kamilla',
        'Asta',
        'Alberte',
        'Amanda',
        'Kristine',
        'Pernille',
        'Nina',
        'Cille',
        'Julia',
        'Danna',
        'Victoria',
        'Solveig',
        'Melanie',
        'Mie',
        'Frederikke',
        'Anne',
        'Maya',
        'Lea',
        'Grace',
        'Marianne',
        'Luna',
        'Mia',
        'Sidsel',
        'Sandra',
        'Charlotte',
        'Malou',
        'Regitze',
        'Zarina',
        'Sabrina',
        'Olivia',
        'Peivand',
        'Marie',
        'Nikoline',
        'Annika',
        'Lisa',
        'Clara',
        'Naja',
        'Tina',
        'Anne-Mette',
        'Yasmin',
        'Christine',
        'Elisabeth',
        'Nicoline',
        'Anja',
        'Synne',
        'Inga',
        'Nawal',
        'Kira',
        'Birthe',
        'Fatii',
        'Anja-Mary',
        'Anne Katrine',
        'Maggie',
        'Linea',
        'Carolina',
        'Andreea',
        'Sine',
        'Sofia',
        'Katia',
        'Manja Elise',
        'Miig',
        'Rebekka',
        'Eline',
        'Bodil'
    ],

    UK: [
        'Amelia',
        'Olivia',
        'Jessica',
        'Emily',
        'Lily',
        'Ava',
        'Isla',
        'Sophie',
        'Mia',
        'Isabella',
        'Evie',
        'Poppy',
        'Ruby',
        'Grace',
        'Sophia',
        'Chloe',
        'Freya',
        'Isabelle',
        'Ella',
        'Charlotte',
        'Scarlett',
        'Daisy',
        'Lola',
        'Holly',
        'Eva',
        'Lucy',
        'Millie',
        'Phoebe',
        'Layla',
        'Maisie',
        'Sienna',
        'Alice',
        'Florence',
        'Lilly',
        'Ellie',
        'Erin',
        'Elizabeth',
        'Imogen',
        'Summer',
        'Molly',
        'Hannah',
        'Sofia',
        'Abigail',
        'Jasmine',
        'Matilda',
        'Megan',
        'Rosie',
        'Lexi',
        'Lacey',
        'Emma',
        'Amelie',
        'Maya',
        'Gracie',
        'Emilia',
        'Georgia',
        'Hollie',
        'Evelyn',
        'Eliza',
        'Amber',
        'Eleanor',
        'Bella',
        'Amy',
        'Brooke',
        'Leah',
        'Esme',
        'Harriet',
        'Anna',
        'Katie',
        'Zara',
        'Willow',
        'Elsie',
        'Annabelle',
        'Bethany',
        'Faith',
        'Madison',
        'Isabel',
        'Rose',
        'Julia',
        'Martha',
        'Maryam',
        'Paige',
        'Heidi',
        'Maddison',
        'Niamh',
        'Skye',
        'Aisha',
        'Mollie',
        'Ivy',
        'Francesca',
        'Darcey',
        'Maria',
        'Zoe',
        'Keira',
        'Sarah',
        'Tilly',
        'Isobel',
        'Violet',
        'Lydia',
        'Sara'
    ],

    Italy: [
        'Giulia',
        'Alessandra',
        'Alessia',
        'Alice',
        'Anastasia',
        'Angela',
        'Anna',
        'Annalisa',
        'Antonella',
        'Arianna',
        'Aurora',
        'Barbara',
        'Beatrice',
        'Benedetta',
        'Bianca',
        'Camilla',
        'Carlotta',
        'Caterina',
        'Chiara',
        'Cinzia',
        'Clara',
        'Claudia',
        'Cristina',
        'Daniela',
        'Debora',
        'Diana',
        'Diletta',
        'Elèna',
        'Eleonora',
        'ELISA',
        'Elisabetta',
        'Emily',
        'Emma',
        'Erika',
        'Fabiana',
        'Federica',
        'Francesca',
        'Gabriella',
        'Gaia',
        'Giada',
        'Ginevra',
        'Giorgia',
        'Giovanna',
        'Giulia',
        'Giusy',
        'Gloria',
        'Greta',
        'Ilaria',
        'Irene',
        'Isabella',
        'Jennifer',
        'Jessica',
        'Lara',
        'Laura',
        'Letizia',
        'Linda',
        'Lisa',
        'Lucia',
        'Lucrezia',
        'Ludovica',
        'Luisa',
        'Manuela',
        'Margherita',
        'Maria',
        'Marika',
        'Marina',
        'Marta',
        'Martina',
        'Mary',
        'Melissa',
        'Michela',
        'Miriam',
        'Monica',
        'Nadia',
        'Nicole',
        'Nicoletta',
        'Noemi',
        'Paola',
        'Rachele',
        'Rebecca',
        'Roberta',
        'Rosa',
        'Rossella',
        'Sabrina',
        'Samantha',
        'Sara',
        'Serena',
        'Silvia',
        'Simona',
        'Sofia',
        'Sofia',
        'Stefania',
        'Stella',
        'Susanna',
        'Teresa',
        'VALE',
        'Valentina',
        'Valeria',
        'Vanessa',
        'Veronica',
        'Viola'
    ],

    France: [
        'Marie',
        'Camille',
        'Manon',
        'Laura',
        'Julie',
        'Marine',
        'Léa',
        'Pauline',
        'Mathilde',
        'Sarah',
        'Marion',
        'Chloé',
        'Anaïs',
        'Lucie',
        'ELODIE',
        'Charlotte',
        'Mélanie',
        'Morgane',
        'Justine',
        'Claire',
        'Emilie',
        'Audrey',
        'Emma',
        'Océane',
        'Clara',
        'Juliette',
        'Amandine',
        'Caroline',
        'Alice',
        'Céline',
        'Aurélie',
        'Margaux',
        'Sophie',
        'Fanny',
        'Noemie',
        'Mélissa',
        'Amélie',
        'Clémence',
        'Lisa',
        'Louise',
        'Maéva',
        'Elise',
        'Estelle',
        'Laëtitia',
        'Ines',
        'Ophélie',
        'Alexandra',
        'Célia',
        'Coralie',
        'Margot',
        'Eva',
        'Cecile',
        'Helene',
        'Aurore',
        'Solène',
        'Elisa',
        'Alexia',
        'Jessica',
        'Julia',
        'Emeline',
        'Cindy',
        'Romane',
        'Laurie',
        'Jade',
        'Agathe',
        'Valentine',
        'Alicia',
        'Charlène',
        'Stéphanie',
        'Jeanne',
        'Laure',
        'Adeline',
        'Laurine',
        'Gaëlle',
        'Lola',
        'Marina',
        'Jennifer',
        'Lucile',
        'Elsa',
        'Clémentine',
        'Sabrina',
        'Myriam',
        'Delphine',
        'Sandra',
        'Coline',
        'Nina',
        'Anna',
        'Angelique',
        'Ludivine',
        'Andréa',
        'Déborah',
        'Zoe',
        'Léna',
        'Victoria',
        'Virginie',
        'Johanna',
        'Charline',
        'Salomé',
        'Floriane',
        'Mégane'
    ],

    Russia: [
        'Nastya',
        'Anastasia',
        'Dasha',
        'Anna',
        'Olga',
        'Maria',
        'Julia',
        'Alina',
        'Irina',
        'Kate',
        'Marina',
        'Polina',
        'Katya',
        'Liza',
        'Tanya',
        'Masha',
        'Alexandra',
        'Ksenia',
        'Mary',
        'Natasha',
        'Daria',
        'Elena',
        'Yana',
        'Ann',
        'Svetlana',
        'Victoria',
        'Ekaterina',
        'Kristina',
        'Arina',
        'Diana',
        'Natalia',
        'Lena',
        'Lera',
        'Kseniya',
        'Sofia',
        'Helen',
        'Anya',
        'Oksana',
        'Tatiana',
        'Anastasiya',
        'Alyona',
        'Darya',
        'Sveta',
        'Yulia',
        'Olya',
        'Olesya',
        'Helena',
        'Katerina',
        'Ira',
        'Alena',
        'Alice',
        'Sasha',
        'Natalya',
        'Nina',
        'Vika',
        'Vera',
        'Veronika',
        'Mariya',
        'Angelina',
        'Ksusha',
        'Valentina',
        'Margarita',
        'Catherine',
        'Sonya',
        'Nataly',
        'Valeria',
        'Lina',
        'Nastia',
        'Lisa',
        'Vasilisa',
        'Katherine',
        'Sophia',
        'Zhenya',
        'Luba',
        'Karina',
        'Elizabeth',
        'Xenia',
        'Inna',
        'Alla',
        'Ulyana',
        'Ksenya',
        'Galina',
        'Aigul',
        'Alex',
        'Jane',
        'Albina',
        'Guzel',
        'Tatyana',
        'Juliya',
        'Madina',
        'Irene',
        'Lyuba',
        'Kira',
        'Varvara',
        'Äàðüÿ',
        'Katia',
        'Evgeniya',
        'Milena',
        'Rita',
        'Varya'
    ],

    Ukraine: [
        'Alina',
        'Anastasia',
        'Ann',
        'Marina',
        'Nastya',
        'Anna',
        'Dasha',
        'Julia',
        'Victoria',
        'Sasha',
        'Maria',
        'Yana',
        'Ilona',
        'Kate',
        'Olga',
        'Liza',
        'Tania',
        'Ira',
        'Anastasiya',
        'Sophie',
        'Sveta',
        'Yulia',
        'Irina',
        'Elena',
        'Masha',
        'Diana',
        'Tatiana',
        'Natasha',
        'Natalia',
        'Oksana',
        'Jane',
        'Polina',
        'Olya',
        'Alyona',
        'Lena',
        'Helen',
        'Alisa',
        'Tanya',
        'Zlata',
        'Vlada',
        'Nastia',
        'Natalya',
        'Julie',
        'Rita',
        'Alexandra',
        'Viktoriya',
        'Asya',
        'Inna',
        'Katrin',
        'Yuliya',
        'Marianna',
        'Ksenia',
        'Olena',
        'Veronica',
        'Svitlana',
        'Valeria',
        'Vika',
        'Valia',
        'Hanna',
        'Irena',
        'Nika',
        'Kseniya',
        'Alex',
        'Sofia',
        'Myroslava',
        'Olia',
        'Olla',
        'Viktoria',
        'Veronika',
        'Iryna',
        'Daria',
        'Taya',
        'Lesia',
        'Irene',
        'Ksusha',
        'Anya',
        'Ivanna',
        'July',
        'Tina',
        'Catherine',
        'Katya',
        'Joanna',
        'Odry',
        'Ulia',
        'Lisa',
        'Katja',
        'Katerina',
        'Arina',
        'Yasia',
        'Kateryna',
        'Genia',
        'Karina?',
        'Ruslana',
        'Marina Bobkova',
        'Marisha',
        'Milorada',
        'Nataly',
        'Olenka',
        'Margarita',
        'Jana'
    ],

    Poland: [
        'Ada',
        'Adrianna',
        'Agata',
        'Agnieszka',
        'Aleksandra',
        'Aleksandra',
        'Alicja',
        'Alina',
        'Amelia',
        'Aneta',
        'Angelika',
        'Anna',
        'Anna',
        'Barbara',
        'Beata',
        'Dagmara',
        'Daria',
        'Dominika',
        'Dorota',
        'Edyta',
        'Eliza',
        'Elzbieta',
        'Emilia',
        'Ewa',
        'Ewelina',
        'Gabriela',
        'Hanna',
        'Iga',
        'Iwona',
        'Izabela',
        'Joanna',
        'Jolanta',
        'Julia',
        'Justyna',
        'Kamila',
        'Karolina',
        'Katarzyna',
        'Katarzyna',
        'Kinga',
        'Klaudia',
        'Lena',
        'Magdalena',
        'Maja',
        'Malwina',
        'Marcelina',
        'Maria',
        'Marika',
        'Marlena',
        'Marta',
        'Martyna',
        'Michalina',
        'Milena',
        'Monika',
        'Natalia',
        'Olga',
        'Oliwia',
        'Patrycja',
        'Paula',
        'Paulina',
        'Roksana',
        'Sandra',
        'Sara',
        'Sonia',
        'Sylwia',
        'Urszula',
        'Weronika',
        'Wiktoria',
        'Wioletta',
        'Zuzanna',
        'Zuzanna'
    ],

    Arab: [
        'Afnan',
        'Aliaa',
        'Aline',
        'Amel',
        'Amina',
        'Amirah',
        'Anais',
        'Arwa',
        'Asmaa',
        'Aya',
        'Babes',
        'Bassant',
        'Bassma',
        'Bouchra',
        'Camelia',
        'Chahinez',
        'Chanez',
        'Cherifa',
        'Chiraz',
        'Cylia',
        'Dalia',
        'Damia',
        'Darin',
        'Dina',
        'Djamila',
        'Donya',
        'Essossilam',
        'Eyenour',
        'Farah',
        'Farida',
        'Fatma',
        'Feriel',
        'Fidy',
        'Ghada',
        'Habibah',
        'Hadjer',
        'Hafsa',
        'Hala',
        'Hana',
        'Hanan',
        'Haya',
        'Hazel',
        'Heba',
        'Ibtissem',
        'Ikram',
        'Ilhem',
        'Iman',
        'Imene',
        'Imi',
        'Jasmine',
        'Jojo',
        'Kahina',
        'Kary',
        'Katia',
        'Khadidja',
        'Lala',
        'Lea',
        'Leila',
        'Lidya',
        'Lili',
        'Lilia',
        'Lobna',
        'Lujain',
        'Lydia',
        'Lylia',
        'Lyna',
        'Lynda',
        'Malak',
        'Manar',
        'Manel',
        'Maria',
        'Mariam',
        'Marwa',
        'Mayar',
        'Menna',
        'Meriem',
        'Mima',
        'Mimi',
        'Mina',
        'Mira',
        'Mounia',
        'Mya',
        'Nabila',
        'Nacéra',
        'Nadeen',
        'Nadia',
        'Naila',
        'Nannosa',
        'Narimane',
        'Nassima',
        'Nawel',
        'Nedal',
        'Nehad',
        'Nerin',
        'Nesmah',
        'Nesrine',
        'Neyla',
        'Nina',
        'Nirmeen',
        'Noha',
        'Norhan',
        'Nour',
        'Ola',
        'Omneya',
        'Passant',
        'Prenom',
        'Rana',
        'Raneem',
        'Rania',
        'Razan',
        'Reem',
        'Rehab',
        'Romaa',
        'Rona',
        'Ryma',
        'Safia',
        'Sally',
        'Salma',
        'Sam',
        'Samaa',
        'Samia',
        'Samira',
        'Sara',
        'Sassi',
        'Selma',
        'Shahirah',
        'Shaimaa',
        'Sherin',
        'Sofia',
        'Sohaila',
        'Sonia',
        'Sophia',
        'Soraya',
        'Souad',
        'Toka',
        'Wafa',
        'Weam',
        'Yara',
        'Yasmine',
        'Yomna',
        'Zahra',
        'Zakia'
    ],

    Pakistan: [
        'Noor',
        'Anam',
        'Mahnoor',
        'Faiza',
        'Komal',
        'Aisha',
        'Mishael',
        'Javeria',
        'Sarah',
        'Mariam',
        'Krishma',
        'Annusheh',
        'Mahrukh',
        'Monazza',
        'Rameen',
        'Ameria',
        'Imama',
        'Safiyah',
        'Fatima',
        'Sonam',
        'Ayesha',
        'Nomi',
        'Zainnah',
        'Zainab',
        'Zoha',
        'Noreen',
        'Amna',
        'Areeba',
        'Kainat',
        'Ifrah',
        'Sana',
        'Mahak',
        'Mahwish',
        'Ramisha',
        'Alayna',
        'Usmaan',
        'Murium',
        'Kheezran',
        'Lintah',
        'Abeer',
        'Fariha',
        'Zahira',
        'Niya',
        'Diyana',
        'Hajra',
        'Zuny',
        'Lintah :)',
        'Sierra',
        'Zakia',
        'Aanya',
        'Afifa',
        'Umara',
        'Neha',
        'Sana Riasat',
        'Rida',
        'Sonia',
        'Tooba',
        'Asma',
        'Unsa',
        'Asmi',
        'Hajrah',
        'Haniya',
        'Momna',
        'Rue'
    ],

    India: [
        'Priyanka',
        'Tanya',
        'Aditi',
        'Shreya',
        'Divya',
        'Anjali',
        'Priya',
        'Tanvi',
        'Gayatri',
        'Anisha',
        'ANGEL',
        'Akansha',
        'Diya',
        'Ananya',
        'Radhika',
        'Pavithra',
        'Sakshi',
        'Juvina',
        'Prachi',
        'Mahima',
        'Riya',
        'Ishita',
        'Shivangi',
        'Shivani',
        'ARCHITA',
        'Vaishnavi',
        'Leah',
        'Liza',
        'Jasmine',
        'Swati',
        'Manisha',
        'Aanchal',
        'Vidhya',
        'Lala',
        'Moii Chhangte',
        'Akanksha',
        'Lopa',
        'Kuhu',
        'Anusha',
        'Nishi',
        'Alisha',
        'Kavya',
        'Anushree',
        'Sania',
        'Keerthi',
        'Akshata',
        'Arti',
        'Manasi',
        'Anoushka',
        'Gitanjali',
        'Anushri',
        'Sushma',
        'Vidushi',
        'Diksha',
        'Mitali',
        'Anamika',
        'Manasa',
        'Anumeha',
        'Richa',
        'Alice',
        'Niharika',
        'Chanchal',
        'Ira',
        'Tejaswini',
        'Sanjana',
        'Apoorva',
        'Aishwarya',
        'Arusha',
        'Isabel',
        'Lavanya',
        'Krithika',
        'Isha',
        'Indhumathi',
        'Natasha',
        'Aswini',
        'Ria',
        'Rhea',
        'Rachel',
        'Urvashi',
        'Namrata',
        'Neha',
        'Ishika',
        'Ann',
        'Sneha',
        'Tenzin',
        'Eesha',
        'Jiffy',
        'Vani',
        'Debbie',
        'Lily',
        'Chandralekha',
        'Seema',
        'Aarushi',
        'Shrinidhi',
        'Nandita',
        'Tisha',
        'Varsha',
        'Sonam',
        'Ramya',
        'N.Priyanka'
    ],

    Philippines: [
        'Nicole',
        'Marie',
        'Kim',
        'Anna',
        'Bea',
        'Sarah',
        'Nikki',
        'Anne',
        'Camille',
        'Sheila',
        'Patricia',
        'Sam',
        'Isabel',
        'Jessica',
        'Pauline',
        'Angela',
        'Kate',
        'Mae',
        'Joyce',
        'Danielle',
        'Alyssa',
        'Trisha',
        'Sophia',
        'Bianca',
        'Victoria',
        'Joan',
        'Tricia',
        'Michelle',
        'Jean',
        'Angel',
        'Lovely',
        'Joramae',
        'Ericka',
        'Belle',
        'Katherine',
        'Bella',
        'Denise',
        'Dianne',
        'Cardel',
        'Sofia',
        'Ella',
        'Angelica',
        'Freya',
        'Anj',
        'Emily',
        'Tessa',
        'Mar',
        'Dominique',
        'Marcia',
        'Macy',
        'AJ',
        'Sabrina',
        'Bridget',
        'Danica',
        'Vanessa',
        'Rachelle Mae',
        'Mackenzie',
        'DIN',
        'Charisse',
        'Cheskka',
        'Jan',
        'Katrina',
        'April',
        'Monica',
        'Kristine',
        'Kristina',
        'Abigail',
        'Cherry',
        'Kath',
        'Leah',
        'Marinol',
        'Jane',
        'Amanda',
        'Yanna',
        'Gabrielle',
        'Mary Kirstein',
        'CHE',
        'Marilyn',
        'Ruth',
        'Mio',
        'Jessa',
        'Erin',
        'Marjorie',
        'Faye',
        'Mary',
        'Karen',
        'Pearl Angel',
        'Crystal',
        'Lala',
        'Grace',
        'Thea',
        'Donna',
        'Trina',
        'Maria',
        'Moirrah',
        'Inday',
        'Weni',
        'Yssa',
        'Jillian',
        'Charmie Arianne'
    ],

    Indonesia: [
        'Sarah',
        'Dian',
        'Aulia',
        'Gita',
        'Nadia',
        'Bella',
        'Putri',
        'Lia',
        'Intan',
        'Abigail',
        'Cindy',
        'Annisa',
        'Syifa',
        'Nurul',
        'Nadya',
        'Maria',
        'Eva',
        'Dinda',
        'Karina',
        'Sharon',
        'Dewi',
        'Nisrina',
        'Dina',
        'Amalia',
        'Jessica',
        'Michelle',
        'Eka',
        'Icha',
        'Tika',
        'Wulan',
        'Gabriella',
        'Clara',
        'Shinta',
        'Vina',
        'Linda',
        'Ira',
        'Dini',
        'Fitri',
        'Sella',
        'Nadira',
        'Jasmine',
        'Defi',
        'Nabila',
        'Tasya',
        'Hana',
        'Hani',
        'Adinda',
        'Claudia',
        'Elsa',
        'Diandra',
        'Vania',
        'Grace',
        'Devi',
        'Indah',
        'Alya',
        'Imelda',
        'Nisa',
        'Sari',
        'Debby',
        'Diah',
        'Arradhiyatul',
        'Amanda',
        'Sheren',
        'Novi',
        'Indonesia',
        'Vanessa',
        'Farah',
        'Ayu',
        'Tyas',
        'Karamina',
        'Angela',
        'Cynthia',
        'Gabriela',
        'Septi',
        'Mila',
        'Muthia',
        'Novia Astri',
        'Kezia',
        'Indri',
        'Veronica',
        'Novita',
        'Ratih',
        'Felicia',
        'Nanda',
        'Rere',
        'Mutiara',
        'Amy',
        'Elisa',
        'Salsa',
        'Anna',
        'Maya',
        'Ellen',
        'Merry',
        'Deasy',
        'Azzahra',
        'Ana',
        'Fira',
        'Ika',
        'Ken',
        'Vivian'
    ],

    Thailand: [
        'Fern',
        'Ploy',
        'Nan',
        'Kanokwan',
        'Mild',
        'Aom',
        'Bow',
        'Arisa',
        'Eve',
        'Sudarat',
        'Pim',
        'Jane',
        'Mint',
        'Nam',
        'Noon',
        'June',
        'Sai',
        'Praew',
        'Nutcha',
        'Wipada',
        'View',
        'Ning',
        'Mind',
        'Benyapa',
        'Ben',
        'Natcha',
        'Patcharee',
        'Fah',
        'Beam',
        'Pam',
        'Kate',
        'Jenny',
        'Natchaya',
        'Toey',
        'Areeya',
        'Benjawan',
        'Tidarat',
        'Atitaya',
        'Kwan',
        'Tangmo',
        'Mook',
        'Nattakan',
        'Cherry',
        'Muk',
        'Pang',
        'Aungkana',
        'Yanisa',
        'Apinya',
        'Matchima',
        'Gift',
        'Fai',
        'Meen',
        'Ying',
        'Nick',
        'Pat',
        'Bam',
        'Bell',
        'Darunee',
        'Jaruwan',
        'Ann',
        'Arissara',
        'Chonticha',
        'Nat',
        'Chanchira',
        'Oil',
        'Thanyaluk',
        'Jasmine',
        'Ice',
        'Jib',
        'Kornkanok',
        'Pop',
        'Thanchanok',
        'Baifern',
        'Panida',
        'Am',
        'Gam',
        'Sukanya',
        'Tik',
        'Chutima',
        'Kanjana',
        'Arunprapa',
        'Rinrada',
        'Sasithorn',
        'Eye',
        'Yok',
        'Prim',
        'Saowalak',
        'Arisara',
        'Nisa',
        'Supichaya',
        'Kotchakorn',
        'Aum',
        'Wassana',
        'Pompam',
        'Phurichaya',
        'Supanida',
        'Nuttiya'
    ],

    China: [
        'Ya Jing',
        'Meng Jie',
        'Meng Lu',
        'Hui Qian',
        'Man Ni',
        'Yu Yan',
        'San Yu',
        'Qian Xue',
        'Xiang Yi',
        'Ling Yun',
        'Qian Xue',
        'Yu Zhen',
        'Ru Xue',
        'Zheng Mei',
        'Mei Lin',
        'Huan Xin',
        'You Xuan',
        'Yu Jia',
        'Ya Nan',
        'Ming Mei',
        'Ke Xin',
        'Hui Qian',
        'Man Ni',
        'Xiang Ru',
        'Yue Chan',
        'Chang Xi',
        'Jing Xiang',
        'Meng Jie',
        'Ling Wei',
        'Mei Lian',
        'Ya Jing',
        'Xue Li',
        'Yi Na',
        'Ya Fu',
        'Yu Ting',
        'Yi Xiang',
        'Jun Yao',
        'Meng Yao',
        'Wan Ting',
        'Rui Jie',
        'Ya Lin',
        'Jing Qi',
        'Yan Ni',
        'Xin Rui',
        'Jing Chen',
        'Xue Hui',
        'Shu Ying',
        'Le Shan',
        'Yue Yi',
        'Yun Xi',
        'Yu Tong',
        'Jing Wen',
        'Tian Yu',
        'Jing Qi',
        'Meng Yao',
        'Jing Chen',
        'Shi Qi',
        'Mei Xuan',
        'Xue Yan',
        'Yu Ting',
        'Xiao Yi',
        'You Xuan',
        'Yu Jia',
        'Ya Nan',
        'Yu Ting',
        'Yue Ting',
        'Yun Xuan',
        'Xin Tong',
        'Pei Ling',
        'Yu Yan',
        'Ling Fei',
        'Yu Xin',
        'Jing Yao',
        'Jin Xuan',
        'Man Ni',
        'Ling Yun',
        'Xin Yan',
        'Yu Zhen',
        'Ru Xue',
        'Zheng Mei',
        'Mei Lin',
        'Huan Xin',
        'You Xuan',
        'Yu Jia',
        'Ya Nan',
        'Ming Mei',
        'Ke Xin',
        'Hui Qian',
        'Man Ni',
        'Xiang Ru',
        'Yue Chan',
        'Chang Xi',
        'Jing Xiang',
        'Meng Jie',
        'Ling Wei',
        'Mei Lian',
        'Ya Jing',
        'Xue Li',
        'Yun Han',
        'Li Zi'
    ],

    Korea: [
        'Kim',
        'Lee',
        'Park',
        'Choi',
        'Minji',
        'Jung',
        'Jeong',
        'Jiwon',
        'Kang',
        'Jieun',
        'Jin',
        'Sujin',
        'Min',
        'Ji Eun',
        'Yujin',
        'Jang',
        'Subin',
        'Jenny',
        'Eunji',
        'Yoon',
        'Han',
        'Song',
        'Ji Young',
        'Shin',
        'Ji Hyun',
        'Jihye',
        'Lim',
        'Jisu',
        'Min Ji',
        'Jo',
        'Ji Won',
        'Sumin',
        'SO YEON',
        'Seo',
        'Kwon',
        'Hyejin',
        'Cho',
        'Amy',
        'Soyeon',
        'Yeji',
        'Minju',
        'Rachel',
        'Hana',
        'So Young',
        'Yang',
        'Hyun',
        'Jiyeon',
        'Jina',
        'Ji Yeon',
        'Yu Jin',
        'Eun Ji',
        'Yejin',
        'Jimin',
        'Ji Hye',
        'Yun',
        'Yuri',
        'Hong',
        'SOHEE',
        'Jiyoung',
        'Grace',
        'Anna',
        'Yuna',
        'Jihyun',
        'Jeon',
        'Hyewon',
        'Mina',
        'Ji-Eun',
        'SORA',
        'Hye Jin',
        'Alice',
        'Dahye',
        'Eun',
        'Sarah',
        'Boram',
        'Hye Won',
        'Sunny',
        'Moon',
        'Jane',
        'Suyeon',
        'Hyemin',
        'Soyoung',
        'Hwang',
        'Su Jin',
        'Sally',
        'Bora',
        'Jessica',
        'Suji',
        'Hanna',
        'Joo',
        'Dasom',
        'Hansol',
        'Oh',
        'Hyun Ji',
        'Jisoo',
        'Hannah',
        'Heejin',
        'Daeun',
        'Hyunji',
        'Juhee',
        'Stella'
    ],

    Japan: [
        'Yuka',
        'Yuki',
        'Saki',
        'Natsumi',
        'Kana',
        'Yui',
        'Ayumi',
        'Haruka',
        'Yuri',
        'Rina',
        'Shiori',
        'Sayaka',
        'Miho',
        'Ayano',
        'Aya',
        'Shiho',
        'Kaori',
        'Mai',
        'Ayaka',
        'Chihiro',
        'Rika',
        'Minami',
        'Risa',
        'Anna',
        'MIKA',
        'Marina',
        'Mina',
        'Mayu',
        'Airi',
        'Kanako',
        'Moe',
        'Arisa',
        'Nana',
        'Ai',
        'Haruna',
        'Misaki',
        'Saya',
        'Yu',
        'Riko',
        'Yumi',
        'Eri',
        'Aki',
        'Mari',
        'Erika',
        'Miki',
        'Satomi',
        'Mao',
        'Mio',
        'Rin',
        'Sakura',
        'Momoko',
        'MAMI',
        'Chika',
        'Manami',
        'Ayana',
        'Asuka',
        'Narumi',
        'Aika',
        'Kaho',
        'Tomoko',
        'Akari',
        'Mizuki',
        'Asami',
        'Nozomi',
        'Mayumi',
        'Chiharu',
        'Sae',
        'Yuna',
        'Natsu',
        'Keiko',
        'Mei',
        'Nanako',
        'Yukiko',
        'Asumi',
        'Hana',
        'Miyu',
        'Nao',
        'Aoi',
        'Megumi',
        'Haru',
        'Kyoko',
        'Akane',
        'Meg',
        'Azusa',
        'Saori',
        'Maki',
        'Miku',
        'Minori',
        'Kay',
        'Emi',
        'Maya',
        'Aimi',
        'Honami',
        'Julia',
        'Kei',
        'Nagisa',
        'Shoko',
        'Chiaki',
        'Kanae',
        'Miyuki'
    ],

    Africa: [
        'Abena',
        'Adede',
        'Bapoto',
        'Chiamaka',
        'Chipo',
        'Dayo',
        'Dede',
        'Ebele',
        'Efua',
        'Folami',
        'Fumbe',
        'Hendaqué',
        'Hlengiwe',
        'Ife',
        'Ifunanya',
        'Kagiso',
        'Kalisha',
        'Kunto',
        'Leena',
        'Lesedi',
        'Makena',
        'Mala',
        'Malaika',
        'Nkosazana',
        'Nkruma',
        'Nubia',
        'Oluchi',
        'Olufemi',
        'Qhikiza',
        'Racheal',
        'Rehema',
        'Rudo',
        'Shanika',
        'Sheena',
        'Sithembile',
        'Ssanyu',
        'Subira',
        'Tamela',
        'Tamika',
        'Taonga',
        'Uzoma',
        'Worknesh',
        'Yaa',
        'Zuri',
        'Adebukola',
        'Adji',
        'Aisha',
        'Alexia',
        'Alice',
        'Andrea',
        'Andrella',
        'Anneke',
        'Annick',
        'Arance',
        'Armelle',
        'Atanga',
        'Ava',
        'BAHATI',
        'Beckie',
        'Bema',
        'Bianca',
        'Bilkissou',
        'Binitubo',
        'Boubakari',
        'Buhle',
        'Canelle',
        'Caressa',
        'Carine',
        'Chanté',
        'Chidinma',
        'Chimdalu',
        'Chinwe',
        'Churibine',
        'Damilola',
        'Dammie',
        'Dara',
        'Destiny',
        'Devine',
        'Disha',
        'Dopsy',
        'Doriane',
        'Emili',
        'Emma',
        'Enora',
        'Erica',
        'Fadeelah',
        'Fatima',
        'Favour',
        'Flore',
        'Franca',
        'Gaella',
        'Grace',
        'Habeeba',
        'Hadassah',
        'Hadiza',
        'Hope',
        'Horreholuwa',
        'Idowu',
        'Ijeoma',
        'Ilse',
        'Ishani',
        'Ivrine',
        'Jade',
        'Jana',
        'Joelle',
        'Kayla',
        'Kayleigh',
        'Kebe',
        'Kemi',
        'Kimone',
        'Krysty',
        'Lara',
        'Laura',
        'Laure',
        'Leah',
        'Leonie',
        'Lily',
        'Lylie',
        'Mairama',
        'Manuela',
        'Marguerite',
        'Mariam',
        'Marvis',
        'Maxine',
        'Mbali',
        'Melody',
        'Mich',
        'Mide',
        'Mignon',
        'Mireille',
        'Monique',
        'Nadia',
        'Naima',
        'Naledi',
        'Nancy',
        'Naomi',
        'Nicola',
        'Nokuthula',
        'Ntar',
        'Nthabiseng',
        'Nzube',
        'Oforlea',
        'Ogechi',
        'Ogunjobi Ifeoluwa',
        'Olariche',
        'Olayemi',
        'Olivia',
        'OLWETHU',
        'Ornella',
        'Oyinlola',
        'Pascale',
        'Poupina',
        'Precious',
        'Princess',
        'Priscilla',
        'Priyanka',
        'Promise',
        'Prudence',
        'Rachida',
        'Raeesa',
        'Redeem',
        'Reka',
        'Risai',
        'Rosy',
        'Saeed',
        'Saint',
        'Sandrine',
        'Saskia',
        'Sheba',
        'Sherifat',
        'Shirley',
        'Solange',
        'Sonia',
        'Stella',
        'Stephanie',
        'Storm',
        'Suchi',
        'Tamilore',
        'Tamy',
        'Tasneem',
        'Tatiana',
        'Tatum',
        'Tayla',
        'Temitope',
        'Teni',
        'Thandeka',
        'Tolu',
        'Torera',
        'Treasure',
        'Triumph',
        'Tshepiso',
        'Ugochi',
        'Uka',
        'Vera',
        'Winnifred',
        'Zeena',
        'Zoe'
    ],

    Western: [
        'Augustina',
        'Alejandra',
        'Anna',
        'Beatriz',
        'Clara',
        'Esther',
        'Natalia',
        'Martina',
        'Nuria',
        'Gabriela',
        'Aaliyah',
        'Ashley',
        'Charlotte',
        'Brooklyn',
        'Chloe',
        'Dorothy',
        'Elizabeth',
        'Harper',
        'Genesis',
        'Jennifer',
        'Lydia',
        'Naomi',
        'Penelope',
        'Ruby',
        'Samantha',
        'Scarlett',
        'Chaya',
        'Emily',
        'Camilla',
        'Annika',
        'Celina',
        'Elena',
        'Franziska',
        'Greta',
        'Katharina',
        'Helene',
        'Isabella',
        'Magdalena',
        'Julia',
        'Eva',
        'Sofie',
        'Emma',
        'Lisa',
        'Isa',
        'Saar',
        'Lieke',
        'Fenna',
        'Roos',
        'Sanne',
        'Maja',
        'Ella',
        'Alva',
        'Molly',
        'Linnea',
        'Amanda',
        'Astrid',
        'Hilde',
        'Ingeborg',
        'Jannike',
        'Karine',
        'Lene',
        'Nora',
        'Giulia',
        'Antonella',
        'Arianna',
        'Caterina',
        'Elèna',
        'Giorgia',
        'Greta',
        'Margherita',
        'Marie',
        'Camille',
        'Manon',
        'Marine',
        'Léa',
        'Mathilde',
        'Anaïs',
        'Aurélie',
        'Margaux',
        'Mégane',
        'Olga',
        'Alina',
        'Luba',
        'Polina',
        'Katya',
        'Tanya',
        'Masha',
        'Daria',
        'Svetlana',
        'Oksana',
        'Anastasiya',
        'Yulia',
        'Ksusha',
        'Vasilisa',
        'Zlata',
        'Inna',
        'Nika',
        'Myroslava',
        'Viktoria',
        'Iryna'
    ],

    Asian: [
        'ya jing',
        'chang xi',
        'hui qian',
        'jin xuan',
        'jing wen',
        'jing xiang',
        'ke xin',
        'li zi',
        'ling fei',
        'ling wei',
        'ling yun',
        'man ni',
        'mei lian',
        'mei lin',
        'mei lin',
        'meng jie',
        'meng lu',
        'meng yao',
        'meng yao',
        'ming mei',
        'pei ling',
        'qian xue',
        'qian xue',
        'ru xue',
        'shi qi',
        'shu ying',
        'xin rui',
        'xue li',
        'xue li',
        'xue yan',
        'ya lin',
        'ya nan',
        'you xuan',
        'you xuan',
        'yu jia',
        'yu ting',
        'yu ting',
        'yu ting',
        'yu xin',
        'Priyanka',
        'Tanya',
        'Aditi',
        'Shreya',
        'Divya',
        'Anjali',
        'Priya',
        'Tanvi',
        'Gayatri',
        'Anisha',
        'Angel',
        'Akansha',
        'Diya',
        'Ananya',
        'Radhika',
        'Pavithra',
        'Sakshi',
        'Juvina',
        'Prachi',
        'Mahima',
        'Riya',
        'Ishita',
        'Shivangi',
        'Shivani',
        'Archita',
        'Vaishnavi',
        'Leah',
        'Yuka',
        'Yuki',
        'Saki',
        'Natsumi',
        'Kana',
        'Yui',
        'Ayumi',
        'Haruka',
        'Yuri',
        'Rina',
        'Shiori',
        'Sayaka',
        'Miho',
        'Ayano',
        'Minji',
        'Jung',
        'Jeong',
        'Jiwon',
        'Kang',
        'Sarah',
        'Dian',
        'Aulia',
        'Gita',
        'Nadia',
        'Bella',
        'Putri',
        'Lia',
        'Intan',
        'Abigail',
        'Cindy',
        'Annisa',
        'Fern',
        'Ploy',
        'Nan'
    ],

    African: [
        'Abena',
        'Chiamaka',
        'Dede',
        'Folami',
        'Hlengiwe',
        'Kagiso',
        'Leena',
        'Mala',
        'Nkruma',
        'Nubia',
        'Rehema',
        'Shanika',
        'Ssanyu',
        'Subira',
        'Taonga',
        'Yaa',
        'Aisha',
        'Andrella',
        'Arance',
        'Ava',
        'Bahati',
        'Bianca',
        'Boubakari',
        'Caressa',
        'Chidinma',
        'Churibine',
        'Dara',
        'Disha',
        'Emili',
        'Erica',
        'Flore',
        'Franca',
        'Habeeba',
        'Hope',
        'Ijeoma',
        'Ivrine',
        'Kayla',
        'Kayleigh',
        'Kimone',
        'Lara',
        'Leah',
        'Lylie',
        'Marguerite',
        'Maxine',
        'Melody',
        'Mignon',
        'Nadia',
        'Naima',
        'Naledi',
        'Nancy',
        'Naomi',
        'Nicola',
        'Nokuthula',
        'Ntar',
        'Nthabiseng',
        'Nzube',
        'Oforlea',
        'Ogechi',
        'Olayemi',
        'Olivia',
        'Ornella',
        'Pascale',
        'Princess',
        'Aliaa',
        'Amirah',
        'Arwa',
        'Aya',
        'Camelia',
        'Cherifa',
        'Chiraz',
        'Dalia',
        'Darin',
        'Dina',
        'Djamila',
        'Farah',
        'Fatma',
        'Ghada',
        'Habibah',
        'Hafsa',
        'Hanan',
        'Haya',
        'Hazel',
        'Ikram',
        'Iman',
        'Imi',
        'Jasmine',
        'Katia',
        'Lala',
        'Lidya',
        'Lili',
        'Lynda',
        'Malak',
        'Mariam',
        'Mimi',
        'Mina',
        'Mira',
        'Nadia',
        'Nedal',
        'Neyla',
        'Noha'
    ]
};
