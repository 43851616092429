<template>
  <div class="SurnameTest">
    <MultipleChoiceInputGrid
      ref="input"
      :items="data.fragments"
      :selections="selectedFragmentIndices"
      @item-click="handleItemClick" />
  </div>
</template>

<script>
import MultipleChoiceInputGrid from '@/modules/games-shared/components/MultipleChoiceInputGrid';

export default {
    components: { MultipleChoiceInputGrid },
    inject: ['transition'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    data() {
        return {
            selectedFragmentIndices: []
        };
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    this.$refs.input.playFeedbackCorrect().then(() => {
                        this.$emit('feedback-completed');
                    });
                } else {
                    this.$refs.input.playFeedbackWrong().then(() => {
                        this.$emit('feedback-completed');
                    });
                }
            });
        }
    },
    methods: {
        handleItemClick(fragmentIndex) {
            if (this.done) {
                return;
            }
            if (this.selectedFragmentIndices.indexOf(fragmentIndex) === -1) {
                this.selectedFragmentIndices.push(fragmentIndex);
            } else {
                this.selectedFragmentIndices = [];
            }
            if (this.selectedFragmentIndices.length === 2) {
                const word = this.selectedFragmentIndices.map(i => this.data.fragments[i]).join('');
                this.transition('SUBMIT_ANSWER', { value: word });
            }
        },
        cheat() {
            this.selectedFragmentIndices = [2, 3];
            this.transition('SUBMIT_ANSWER', { value: this.data.fasit });
        }
    }
};
</script>

<style scoped lang="scss">
.SurnameTest {
}
.feedback {
    padding: 1em;
    background-color: red;

    &.correct {
        background-color: green;
    }
}
</style>
